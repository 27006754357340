import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { GetPolicyWalletsQuery } from '@prcins/utils';
import { map } from 'rxjs/operators';
import { HttpClient, HttpParams } from '@angular/common/http';
import { PAYMENT_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var ManagePaymentsProviderService = /** @class */ (function () {
    function ManagePaymentsProviderService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
    }
    Object.defineProperty(ManagePaymentsProviderService.prototype, "data", {
        get: function () {
            return this._data;
        },
        set: function (value) {
            this._data = value;
        },
        enumerable: true,
        configurable: true
    });
    ManagePaymentsProviderService.prototype.getPolicyPaymentDetails = function (userId, policyNumber, appName) {
        var _this = this;
        return this.apollo
            .watchQuery({
            query: GetPolicyWalletsQuery,
            variables: {
                userId: userId,
                policyNumber: policyNumber,
                appName: appName
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }), map(function (_a) {
            var getWallets = _a.getWallets;
            return getWallets;
        }), map(function (wallets) {
            if (wallets) {
                wallets.forEach(function (wallet) {
                    if (wallet.paymentType === "CC" || wallet.paymentType === "ATM") {
                        var savedCardType = wallet.cardType ? wallet.cardType.toLowerCase() : "";
                        switch (savedCardType) {
                            case "Visa".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/visa-logo.png";
                                wallet.cardDisplayType = "Visa";
                                break;
                            case "Discover".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/discover-logo.png";
                                wallet.cardDisplayType = "Discover";
                                break;
                            case "Mastercard".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/mastercard-logo.png";
                                wallet.cardDisplayType = "Master Card";
                                break;
                            case "Americanexpress".toLowerCase():
                                wallet.imageUrl = "assets/png/payments/amex-logo.gif";
                                wallet.cardDisplayType = "American Express";
                                break;
                            default:
                                if (wallet.paymentType === "CC") {
                                    wallet.imageUrl = "assets/png/payments/generic-credit-card.png";
                                }
                                else {
                                    wallet.imageUrl = "assets/png/payments/generic-debit-card.png";
                                }
                                wallet.cardDisplayType = "     ";
                                break;
                        }
                        var m = wallet.expirationMonth;
                        wallet.expirationMonth = (m < 10 ? '0' : '') + m;
                        wallet.expirationDate = wallet.expirationMonth + "/" + wallet.expirationYear;
                        wallet.expiration = _this.getExpiration(wallet);
                    }
                    else if (wallet.paymentType === "ACH") {
                        wallet.accountType = wallet.transType === "CHQ" ? "Checking" : "Savings";
                    }
                    wallet.modifiedDate = new Date(wallet.modifiedDate);
                    wallet.defaultImage = wallet.defaultInd === "Yes" ? "assets/svgs/default-star-icon.svg" : "assets/svgs/not-default-star-icon.svg";
                });
                return wallets;
            }
        }), map(function (wallets) { if (wallets) {
            wallets.sort(_this.compareByModifiedDate);
        } ; return wallets; }), map(function (wallets) { if (wallets) {
            wallets.sort(_this.compareByDefault);
        } ; return wallets; }));
    };
    ManagePaymentsProviderService.prototype.getExpiration = function (wallet) {
        var result = "";
        // month from server starts from 1, so getting next month, 1st day
        var expDate = new Date(wallet.expirationYear, wallet.expirationMonth, 1);
        var now = new Date();
        var diff = expDate.getTime() - now.getTime();
        var diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
        if (diffDays <= 0) {
            result = "expired";
        }
        else if (diffDays <= 30) {
            result = "expiring";
        }
        return result;
    };
    ManagePaymentsProviderService.prototype.compareByModifiedDate = function (a, b) {
        return b.modifiedDate.getTime() - a.modifiedDate.getTime(); // Sort DESC
    };
    ManagePaymentsProviderService.prototype.compareByDefault = function (a, b) {
        if (a.defaultInd === "Yes")
            return -1;
        return 0;
    };
    ManagePaymentsProviderService.prototype.createWallet = function (payload, source) {
        if (source === void 0) { source = "eservice"; }
        if (!source) {
            source = "eservice";
        }
        var params = new HttpParams().set("source", source);
        return this.http.post(PAYMENT_URL.ManagePayment, payload, { params: params });
    };
    ManagePaymentsProviderService.prototype.deleteWallet = function (userId, policyNumber, wallet) {
        var params = new HttpParams({ encoder: new CustomEncoder() })
            .set("policyNumber", policyNumber)
            .set("app", "eservice")
            .set("userId", userId);
        return this.http.delete(PAYMENT_URL.ManagePayment + "/" + wallet.debitAccount + "/" + wallet.walletId, { params: params });
    };
    ManagePaymentsProviderService.prototype.changeDefaultWallet = function (userId, policyNumber, wallet) {
        var params = new HttpParams({ encoder: new CustomEncoder() })
            .set("policyNumber", policyNumber)
            .set("app", "eservice")
            .set("userId", userId)
            .set("selection", wallet.defaultInd === "Yes" ? "No" : "Yes");
        return this.http.put(PAYMENT_URL.ManagePayment + "/default/" + wallet.debitAccount + "/" + wallet.walletId, {}, { params: params });
    };
    ManagePaymentsProviderService.prototype.editWallet = function (payload) {
        var params = new HttpParams().set("source", "eservice");
        return this.http.put(PAYMENT_URL.ManagePayment, payload, { params: params });
    };
    ManagePaymentsProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ManagePaymentsProviderService_Factory() { return new ManagePaymentsProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: ManagePaymentsProviderService, providedIn: "root" });
    return ManagePaymentsProviderService;
}());
export { ManagePaymentsProviderService };
var CustomEncoder = /** @class */ (function () {
    function CustomEncoder() {
    }
    CustomEncoder.prototype.encodeKey = function (key) {
        return encodeURIComponent(key);
    };
    CustomEncoder.prototype.encodeValue = function (value) {
        return encodeURIComponent(value);
    };
    CustomEncoder.prototype.decodeKey = function (key) {
        return decodeURIComponent(key);
    };
    CustomEncoder.prototype.decodeValue = function (value) {
        return decodeURIComponent(value);
    };
    return CustomEncoder;
}());
