import { Apollo } from 'apollo-angular';
import { Observable, Subject, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { DocumentQuery, EdocsListQuery, PolicyInfoQuery } from '@prcins/utils';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DOCUMENTS_URL } from '../constants/documents.urls';
import { EDOCS_URL, DOCS_URL, LOGIN_URL } from '@prcins/constants';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
var DocumentService = /** @class */ (function () {
    function DocumentService(apollo, httpClient) {
        this.apollo = apollo;
        this.httpClient = httpClient;
        this.CACHE = {};
        this.docsServiceError$ = new Subject();
    }
    DocumentService.prototype.getDocuments = function (policyNumber, documentType) {
        return this.apollo
            .watchQuery({
            query: DocumentQuery,
            variables: {
                policyNumber: policyNumber,
                documentType: documentType
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            var sortedData = data.policyDocuments;
            if (sortedData.length > 0) {
                sortedData.sort(function (a, b) {
                    return b.docDate - a.docDate;
                });
            }
            return sortedData;
        }));
    };
    DocumentService.prototype.getFirstName = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyInfoQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policySearchInfo.firstName;
        }));
    };
    DocumentService.prototype.requestAutoIdCard = function (userId, policyNumber, term, actionType) {
        var params = new HttpParams({})
            .set("actionType", actionType);
        return this.httpClient.post(DOCUMENTS_URL.auto.request + "/" + userId + "/" + policyNumber + "/" + term, {}, { params: params });
    };
    DocumentService.prototype.requestToken = function (policyNumber, docType) {
        var params = new HttpParams({});
        return this.httpClient.get(DOCUMENTS_URL.requestToken + "/" + policyNumber + "/" + docType);
    };
    DocumentService.prototype.getEdocsList = function (username) {
        return this.apollo
            .watchQuery({
            query: EdocsListQuery,
            variables: {
                username: username
            },
            fetchPolicy: 'network-only'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account;
        }));
    };
    DocumentService.prototype.saveEdocsPreference = function (updatedEdocs, policies) {
        var _this = this;
        var updatePostList = policies.map(function (policyNumber) {
            var _a = updatedEdocs[policyNumber], emailAddress = _a.emailAddress, subscriptionFlag = _a.subscriptionFlag;
            return _this.httpClient.post(EDOCS_URL.SavePreference, {
                policyNumber: policyNumber,
                emailAddress: emailAddress,
                subscriptionFlag: subscriptionFlag,
                source: 'CI'
            });
        });
        return forkJoin(updatePostList.slice());
    };
    DocumentService.prototype.eDiscloserAudit = function (payload) {
        return this.httpClient.post(LOGIN_URL.AuditUrl, payload);
    };
    DocumentService.prototype.getClaimEstimateDocuments = function (policyNumber, claimNumber) {
        var claimEstimateURL = environment.BASE_URL + "/docs/" + policyNumber + "/claims/" + claimNumber + "/estimates";
        return this.httpClient.get(claimEstimateURL).pipe(map(function (data) {
            var sortedData = data;
            if (sortedData.length > 0) {
                sortedData.sort(function (a, b) {
                    return b.docDate - a.docDate;
                });
            }
            return sortedData;
        }));
    };
    DocumentService.prototype.validateDeclarationPageAccess = function (policyNumber) {
        return this.httpClient.get(DOCS_URL.validateDecPage + "/" + policyNumber);
    };
    DocumentService.prototype.pdfURL = function (url) {
        //return this.httpClient.get(url);
        return this.httpClient.get(url, { responseType: 'arraybuffer' });
    };
    DocumentService.prototype.buildDeclarationPageUrl = function (policyNumber, token) {
        return DOCS_URL.downloadDeclaration + "/" + policyNumber;
    };
    DocumentService.prototype.checkIfDocumentAvailableInIR = function (documentURL) {
        return this.httpClient.get(documentURL, { responseType: 'arraybuffer' });
    };
    DocumentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentService_Factory() { return new DocumentService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient)); }, token: DocumentService, providedIn: "root" });
    return DocumentService;
}());
export { DocumentService };
