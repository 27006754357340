import * as tslib_1 from "tslib";
import { GuardNotificationBase } from '../guard-notification-base/guard-notification-base';
var MrbNotificationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(MrbNotificationComponent, _super);
    function MrbNotificationComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return MrbNotificationComponent;
}(GuardNotificationBase));
export { MrbNotificationComponent };
