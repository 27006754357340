import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { CURRENT_POLICY } from '@prcins/constants';
import { CLAIM_URL } from './constant/claims.urls';
import { ClaimsSearchQuery, ClaimsDetailsQuery } from '@prcins/utils';
import { LOGIN_URL } from '@prcins/constants';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
import * as i2 from "@angular/common/http";
var ClaimsBasicService = /** @class */ (function () {
    function ClaimsBasicService(apollo, http) {
        this.apollo = apollo;
        this.http = http;
    }
    ClaimsBasicService.prototype.claimsSearchQuery = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: ClaimsSearchQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            var sortedData = data.claimsSearch;
            sortedData.sort(function (a, b) {
                return (new Date(b.dateOfLoss).getTime() -
                    new Date(a.dateOfLoss).getTime());
            });
            return sortedData;
        }));
    };
    ClaimsBasicService.prototype.claimDetailsQuery = function (policyNumber, claimNumber) {
        return this.apollo.watchQuery({
            query: ClaimsDetailsQuery,
            variables: {
                policyNumber: policyNumber,
                claimNumber: claimNumber
            }
        }).valueChanges;
    };
    ClaimsBasicService.prototype.getClaimsTalkUrl = function (data) {
        return this.http.post(CLAIM_URL.claimstalkurl, data);
    };
    ClaimsBasicService.prototype.claimPaymentOptions = function (claimNumber) {
        return this.http.get(CLAIM_URL.getclaimspaymentoptionurl + '/' + claimNumber);
    };
    ClaimsBasicService.prototype.claimPaymentEftAccounts = function (policyNumber, claimNumber) {
        console.log(policyNumber);
        return this.http.get(CLAIM_URL.getclaimeftaccounturl + '/' + policyNumber + '/' + claimNumber);
    };
    ClaimsBasicService.prototype.updateClaimsPaymentOption = function (claimNumber, data) {
        return this.http.post(CLAIM_URL.updateClaimsPaymentOption + '/' + claimNumber, data);
    };
    ClaimsBasicService.prototype.eDiscloserAudit = function (payload) {
        return this.http.post(LOGIN_URL.AuditUrl, payload);
    };
    ClaimsBasicService.prototype.getNotificationDetails = function (claimNumber) {
        return this.http.get(CLAIM_URL.getclaimNotificationurl + '/' + claimNumber);
    };
    ClaimsBasicService.prototype.updateNotificationDetails = function (claimNumber, payload) {
        return this.http.post(CLAIM_URL.updateClaimNotificationurl + '/' + claimNumber, payload);
    };
    ClaimsBasicService.prototype.selectRepairShop = function () {
        // TODO - implement HTTP call when API will be available
    };
    ClaimsBasicService.prototype.callCarPicsApp = function (claimId, claimNumber) {
        // TODO - implement HTTP call when API will be available
        // return this.http.get(
        //   CLAIM_URL.getAppraisalDetailsUrl + '/' + claimNumber
        // );
        return new Observable();
    };
    ClaimsBasicService.prototype.searchRepairShop = function (searchCity) {
        // TODO - implement HTTP call when API will be available
    };
    ClaimsBasicService.prototype.createDispatch = function (data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.createDispatch, data);
    };
    ClaimsBasicService.prototype.getDispatch = function (claimNumber) {
        return this.http.get(CLAIM_URL.getDispatch + "/" + claimNumber + "/" + sessionStorage.getItem(CURRENT_POLICY));
    };
    ClaimsBasicService.prototype.assignRepresentative = function (data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.assignRepresentative, data);
    };
    ClaimsBasicService.prototype.getRepresentatives = function (data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.getRepresentatives, data);
    };
    ClaimsBasicService.prototype.reassignRepresentative = function (data) {
        data['policyNumber'] = sessionStorage.getItem(CURRENT_POLICY);
        return this.http.post(CLAIM_URL.reassignRepresentative, data);
    };
    ClaimsBasicService.prototype.getClaimSpecificDocs = function (claimNumber) {
        var url = environment.BASE_URL + "/docs/" + sessionStorage.getItem(CURRENT_POLICY) + "/claims/" + claimNumber;
        return this.http.get(url);
    };
    ClaimsBasicService.prototype.getClaimDashboard = function (claimNumber) {
        var url = environment.BASE_URL + "/claims/dashboard/" + sessionStorage.getItem(CURRENT_POLICY) + "/" + claimNumber;
        return this.http.get(url);
    };
    ClaimsBasicService.prototype.getClaimFaqs = function () {
        var url = environment.BASE_URL + "/common/faqs/all";
        return this.http.get(url);
    };
    ClaimsBasicService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimsBasicService_Factory() { return new ClaimsBasicService(i0.ɵɵinject(i1.Apollo), i0.ɵɵinject(i2.HttpClient)); }, token: ClaimsBasicService, providedIn: "root" });
    return ClaimsBasicService;
}());
export { ClaimsBasicService };
