import { Observable } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { ACCOUNTS_URL, USER_ID, ADMIN_URL, REGISTRATION_URL } from '@prcins/constants';
import { SecurityQuestionListQuery, ManageAlertsQuery, ManageAlertsPolicyList, ManageRemainderList, UtilityProviderService, AllAssociatedPolicyQuery } from '@prcins/utils';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
import * as i3 from "../../../../utils/src/lib/services/utility-provider.service";
var AccountsProviderService = /** @class */ (function () {
    function AccountsProviderService(http, apollo, utility) {
        this.http = http;
        this.apollo = apollo;
        this.utility = utility;
    }
    AccountsProviderService.prototype.getSecurityQuestions = function (username) {
        return this.apollo
            .watchQuery({
            query: SecurityQuestionListQuery,
            variables: {
                username: username
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account;
        }));
    };
    AccountsProviderService.prototype.getAllAssociatedPolicies = function (username, fetchPolicy) {
        if (fetchPolicy === void 0) { fetchPolicy = 'cache-first'; }
        return this.apollo
            .watchQuery({
            query: AllAssociatedPolicyQuery,
            variables: {
                username: username
            },
            fetchPolicy: fetchPolicy
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyAssociations.registeredPolicies;
        }));
    };
    AccountsProviderService.prototype.getRegisteredPolicies = function (username, fetchPolicy) {
        if (fetchPolicy === void 0) { fetchPolicy = 'cache-first'; }
        return this.apollo
            .watchQuery({
            query: ManageAlertsPolicyList,
            variables: {
                username: username
            },
            fetchPolicy: fetchPolicy
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account.registeredPolicies;
        }));
    };
    AccountsProviderService.prototype.getPolicyReminders = function (username) {
        return this.apollo
            .watchQuery({
            query: ManageRemainderList,
            variables: {
                username: username
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account.profile.registeredPolicies;
        }));
    };
    AccountsProviderService.prototype.getManageAlertsConfigs = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: ManageAlertsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.manageAlerts;
        }));
    };
    AccountsProviderService.prototype.updateEmail = function (newEmail, userId) {
        return this.http.post(ACCOUNTS_URL.ChangeEmail + "/" + userId + "/idg_user_group", { newEmail: newEmail, userId: userId });
    };
    AccountsProviderService.prototype.updatePassword = function (currentPassword, newPassword, userId) {
        return this.http.post(ACCOUNTS_URL.ChangePassword + "/" + userId + "/idg_user_group", { currentPassword: currentPassword, newPassword: newPassword, userId: userId });
    };
    AccountsProviderService.prototype.updateSecurityQuestions = function (questionsPayload, userId) {
        return this.http.post(ACCOUNTS_URL.ChangeSecurityQuestions + "/" + userId + "/idg_user_group", questionsPayload);
    };
    AccountsProviderService.prototype.updateScheduledReminder = function (policyNumber, remindersFlag) {
        var userType = this.utility.getUserType();
        return this.http.put(ACCOUNTS_URL.ScheduledReminder + "/" + sessionStorage.getItem(USER_ID) + "/" + userType + "/" + policyNumber + "/" + remindersFlag, null);
    };
    AccountsProviderService.prototype.deletePolicy = function (policyNumber) {
        var userType = this.utility.getUserType();
        return this.http.delete(ACCOUNTS_URL.DeletePolicy + "/" + sessionStorage.getItem(USER_ID) + "/" + userType + "/" + policyNumber);
    };
    AccountsProviderService.prototype.disableAccount = function (policy) {
        return this.http.delete(ADMIN_URL.disableAccount + "/" + sessionStorage.getItem(USER_ID) + "/idg_user_group/" + policy.policyNumber);
    };
    AccountsProviderService.prototype.getTextAlertDetails = function (userId) {
        return this.http.get(REGISTRATION_URL.TextAlertDetails + "/" + userId);
    };
    AccountsProviderService.prototype.addUpdateTextAlertDetails = function (payload) {
        return this.http.post(REGISTRATION_URL.TextAlertAddUpdate, payload);
    };
    AccountsProviderService.prototype.stepOneRegistration = function (payload) {
        return this.http.post(REGISTRATION_URL.TextAlertSendOtpVerification, payload);
    };
    AccountsProviderService.prototype.stepTwoRegistration = function (payload) {
        return this.http.post(REGISTRATION_URL.TextAlertOTPVerify, payload);
    };
    AccountsProviderService.prototype.getAlertPreferences = function (policyEmail) {
        return this.http.get(ACCOUNTS_URL.alertPreferences + "/" + policyEmail);
    };
    AccountsProviderService.prototype.saveAlertPreference = function (request) {
        return this.http.post(ACCOUNTS_URL.alertPreferences, request);
    };
    AccountsProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AccountsProviderService_Factory() { return new AccountsProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo), i0.ɵɵinject(i3.UtilityProviderService)); }, token: AccountsProviderService, providedIn: "root" });
    return AccountsProviderService;
}());
export { AccountsProviderService };
