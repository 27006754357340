import { HttpBackend, HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "@prcins/environments";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var EsignService = /** @class */ (function () {
    function EsignService(handler, http) {
        this.handler = handler;
        this.http = http;
        this.httpClient = new HttpClient(handler);
    }
    EsignService.prototype.getEligibleForms = function (policies) {
        var body = { policies: policies };
        return this.http.post(environment.BASE_URL + "/esign/eligible/forms", body);
    };
    EsignService.prototype.viewDoc = function (policyNumber, eSignId, formId, token) {
        var headers = new HttpHeaders({
            'Authorization': "Bearer " + token
        });
        var apiUrl = environment.BASE_URL + ("/esign/forms/pdf/" + policyNumber + "/" + eSignId + "/" + formId);
        return this.httpClient.get(apiUrl, { headers: headers, responseType: 'text' });
    };
    EsignService.prototype.submitESign = function (currentForm) {
        var headers = new HttpHeaders({
            'Authorization': "Bearer " + currentForm.token
        });
        var apiUrl = environment.BASE_URL + "/esign/forms";
        var body = {
            "policyNumber": currentForm.policyNumber,
            "firstName": currentForm.firstName,
            "lastName": currentForm.lastName,
            "prefix": null,
            "initial": null,
            "sob": "AI",
            "esignId": currentForm.eSignId,
            "fid": null,
            "esignDate": null,
            "appName": "ESERVICE"
        };
        return this.httpClient.post(apiUrl, body, { headers: headers });
    };
    EsignService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EsignService_Factory() { return new EsignService(i0.ɵɵinject(i1.HttpBackend), i0.ɵɵinject(i1.HttpClient)); }, token: EsignService, providedIn: "root" });
    return EsignService;
}());
export { EsignService };
