import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DiscardComponent, BankFormProviderService, FormValidatorService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
import { Bank } from '../model/wallet';
var AddBankComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddBankComponent, _super);
    function AddBankComponent(bankFormProvider, managePaymentsProviderService, router, route, formValidator) {
        var _this = _super.call(this) || this;
        _this.bankFormProvider = bankFormProvider;
        _this.managePaymentsProviderService = managePaymentsProviderService;
        _this.router = router;
        _this.route = route;
        _this.formValidator = formValidator;
        _this.showSpinner = false;
        _this.showError = false;
        _this.agentInfo = {};
        return _this;
    }
    AddBankComponent.prototype.ngOnInit = function () {
        this.eftForm = this.bankFormProvider.getForm();
        this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');
        this.setAgentInfo();
    };
    AddBankComponent.prototype.doAddBank = function () {
        var _this = this;
        this.formValidator.validateAllFormFields(this.eftForm);
        if (this.eftForm.valid) {
            this.showSpinner = true;
            var newBank = new Bank();
            var accountNumber = this.eftForm.get('accountNumber').value;
            newBank.debitAccount = accountNumber;
            newBank.debitRouting = this.eftForm.get('routingNumber').value;
            newBank.creditAccount = this.policyNumber;
            newBank.accountEmail = sessionStorage.getItem(USER_ID);
            newBank.paymentType = "ACH";
            var accountType = this.eftForm.get('accountType').value;
            if (accountType === 'C') {
                newBank.transType = "CHECKING";
            }
            else if (accountType === 'S') {
                newBank.transType = "SAVINGS";
            }
            newBank.accountNickName = new Date().getTime() + " ***** " + accountNumber.substring(accountNumber.length - 4); // TODO - remove after ACI Speedpay fix
            this.managePaymentsProviderService.createWallet(newBank).subscribe(function (response) {
                if (response == null || response.status === 'success' && response.error === "") {
                    _this.navigateToManagePayments('true');
                }
                else {
                    alert(response.status + ", " + response.errorMessage);
                    // TODO - error
                }
            }, function (error) {
                _this.httpErrorResponse();
            });
        }
    };
    AddBankComponent.prototype.navigateToManagePayments = function (isSuccess) {
        sessionStorage.setItem('editWalletSuccess', isSuccess);
        this.router.navigate(['../manage'], {
            relativeTo: this.route
        });
    };
    AddBankComponent.prototype.cancelTransaction = function () {
        this.navigateToManagePayments('false');
    };
    AddBankComponent.prototype.httpErrorResponse = function () {
        this.showSpinner = false;
        this.showError = true;
    };
    AddBankComponent.prototype.setAgentInfo = function () {
        var agentDetails = sessionStorage.agentInfo;
        if (agentDetails) {
            this.agentInfo = JSON.parse(agentDetails);
        }
        this.generalPhone = sessionStorage.getItem('general-phone');
        this.channel = sessionStorage.channel;
    };
    return AddBankComponent;
}(DiscardComponent));
export { AddBankComponent };
