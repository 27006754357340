import { OnInit } from '@angular/core';
import { environment } from '@prcins/environments';
var UTM_PARAMS = {
    "Payment_Confirmation": "utm_source=Cross-sell&utm_medium=payment-confirmation&ad=desktop",
    "Address_Change": "utm_source=Cross-sell&utm_medium=address-change&ad=desktop",
    "Eservice_Homepage": "utm_source=Cross-sell&utm_medium=eservice-homepage&ad=desktop",
    "Coverage_Details": "utm_source=Cross-sell&utm_medium=cov-details&ad=desktop"
};
var CROSS_SELL_URL = "" + environment.CROSS_SELL_URL;
var CrossSellPromotionWidgetComponent = /** @class */ (function () {
    function CrossSellPromotionWidgetComponent() {
        this._utmParams = "";
        this.crossPromotionURL = CROSS_SELL_URL;
    }
    Object.defineProperty(CrossSellPromotionWidgetComponent.prototype, "pageType", {
        set: function (pageType) {
            if (pageType && UTM_PARAMS[pageType]) {
                this._utmParams = UTM_PARAMS[pageType];
                this.crossPromotionURL = CROSS_SELL_URL + "/#/?" + this._utmParams;
            }
            else {
                this.crossPromotionURL = CROSS_SELL_URL;
            }
        },
        enumerable: true,
        configurable: true
    });
    ;
    CrossSellPromotionWidgetComponent.prototype.ngOnInit = function () {
    };
    return CrossSellPromotionWidgetComponent;
}());
export { CrossSellPromotionWidgetComponent };
