import * as tslib_1 from "tslib";
import { FormBuilder, Validators } from '@angular/forms';
import { cardNumberValidatorFn, expiryDateValidatorFn } from '../validators/payment-form.validators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var CardFormProviderService = /** @class */ (function () {
    function CardFormProviderService(fb) {
        this.fb = fb;
    }
    CardFormProviderService.prototype.getForm = function (isZipRequired) {
        if (isZipRequired === void 0) { isZipRequired = false; }
        var formControls = {
            cardNumber: [
                '',
                [Validators.required, Validators.minLength(15), cardNumberValidatorFn()]
            ],
            cardType: [''],
            maskedCardNumber: ['', [Validators.required, Validators.minLength(15)]],
            expirationMonth: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationYear: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationDate: ['', [Validators.required, expiryDateValidatorFn()]]
        };
        if (isZipRequired) {
            formControls = tslib_1.__assign({}, formControls, { cardZip: ['', [Validators.required, Validators.pattern(/\d{5}/)]] });
        }
        return this.fb.group(formControls);
    };
    CardFormProviderService.prototype.getEditCardForm = function (isZipRequired) {
        if (isZipRequired === void 0) { isZipRequired = false; }
        var formControls = {
            cardType: [''],
            expirationMonth: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationYear: ['%%', Validators.pattern(/^[0-9]*$/)],
            expirationDate: ['', [Validators.required, expiryDateValidatorFn()]],
            securityCode: ['', [Validators.required, Validators.minLength(3)]],
            maskedSecurityCode: ['', [Validators.required, Validators.minLength(3)]]
        };
        if (isZipRequired) {
            formControls = tslib_1.__assign({}, formControls, { cardZip: ['', [Validators.required, Validators.pattern(/\d{5}/)]] });
        }
        return this.fb.group(formControls);
    };
    CardFormProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CardFormProviderService_Factory() { return new CardFormProviderService(i0.ɵɵinject(i1.FormBuilder)); }, token: CardFormProviderService, providedIn: "root" });
    return CardFormProviderService;
}());
export { CardFormProviderService };
