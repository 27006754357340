import { setContext } from "apollo-link-context";
import { HttpLink } from 'apollo-angular-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { environment } from '@prcins/environments';
import introspectionQueryResultData from "./utils/fragment.types";
import { TOKEN } from '@prcins/constants';
import { ApolloLink } from 'apollo-link';
var baseURL = sessionStorage.getItem('baseURL') || environment.BASE_URL;
var uri = baseURL + "/api";
var fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: introspectionQueryResultData
});
var ɵ0 = function (_, _a) {
    var headers = _a.headers;
    var token = sessionStorage.getItem(TOKEN);
    if (!headers) {
        headers = {};
    }
    /*if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }*/
    return {
        headers: headers
    };
};
var auth = setContext(ɵ0);
var ɵ1 = function (_a) {
    var graphQLErrors = _a.graphQLErrors, networkError = _a.networkError;
    if (graphQLErrors)
        graphQLErrors.map(function (_a) {
            var message = _a.message, locations = _a.locations, path = _a.path;
            alert("GQL Error");
            console.log("[GraphQL error]: Message: " + message + ", Location: " + locations + ", Path: " + path);
        });
    if (networkError)
        console.log("[Network error]: " + networkError);
};
var errorLink = onError(ɵ1);
export function createApollo(httpLink) {
    var httpLinkWithErrorHandling = ApolloLink.from([
        // errorLink,
        httpLink.create({ uri: uri }),
    ]);
    return {
        link: auth.concat(httpLinkWithErrorHandling).concat(errorLink),
        cache: new InMemoryCache({
            fragmentMatcher: fragmentMatcher,
            dataIdFromObject: function (object) {
                if (object.__typename === "AddressDTO") {
                    return object.id + "_Address";
                }
                else if (object.__typename === "VehicleDTO") {
                    return object.id + "_Vehicle";
                }
                return object.id;
            }
        })
    };
}
var GraphQLModule = /** @class */ (function () {
    function GraphQLModule() {
    }
    return GraphQLModule;
}());
export { GraphQLModule };
export { ɵ0, ɵ1 };
