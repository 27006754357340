import { HttpClient } from '@angular/common/http';
import { of, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { LOGIN_URL } from './constants/urls.constants';
import { PAYMENT_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PolicyLookupService = /** @class */ (function () {
    function PolicyLookupService(http) {
        this.http = http;
    }
    PolicyLookupService.prototype.policyLookup = function (policy) {
        if (this.isQPayOpen(policy.policyNumber)) {
            return this.authenticate(policy);
        }
        return of({});
    };
    PolicyLookupService.prototype.authenticate = function (payload) {
        console.log("payload", payload);
        return this.http.post(LOGIN_URL.Authenticate, payload).pipe(catchError(function (error) {
            return throwError(error);
        }));
    };
    PolicyLookupService.prototype.eDiscloserAudit = function (payload) {
        console.log("payload", payload);
        return this.http.post(LOGIN_URL.AuditUrl, payload).pipe(catchError(function (error) {
            return throwError(error);
        }));
    };
    PolicyLookupService.prototype.isQPayOpen = function (policyNumber) {
        var inActivePrefixes = [
            'PRU',
            'HPU',
            'MAA',
            'FFA',
            'TLA',
            'MWU'
        ];
        var prefix = policyNumber.substring(0, 3);
        var result = inActivePrefixes.indexOf(prefix) > -1 ? false : true;
        return result;
    };
    PolicyLookupService.prototype.loadUserFromToken = function (token) {
        return this.http.post("" + PAYMENT_URL.TokenLoad, { token: token });
    };
    PolicyLookupService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyLookupService_Factory() { return new PolicyLookupService(i0.ɵɵinject(i1.HttpClient)); }, token: PolicyLookupService, providedIn: "root" });
    return PolicyLookupService;
}());
export { PolicyLookupService };
