import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { NavigationProviderService } from '@prcins/utils';
import { BRAND, Brand, ADMIN } from '@prcins/constants';
var DesktopHeaderComponent = /** @class */ (function () {
    function DesktopHeaderComponent(navigationProvider) {
        this.navigationProvider = navigationProvider;
        this.logOut = new EventEmitter();
        this.softLogOut = new EventEmitter();
        this.brand = localStorage.getItem(BRAND);
        this.mainPage = Brand[this.brand].urlEnterpriseWeb;
    }
    DesktopHeaderComponent.prototype.ngOnInit = function () {
        this.navMenu = this.activeMenus;
        this.isAdmin = sessionStorage.getItem(ADMIN) === 'Y';
    };
    DesktopHeaderComponent.prototype.menuNavigationHandler = function (methodName) {
        this.navigationProvider[methodName]();
    };
    DesktopHeaderComponent.prototype.onLogout = function () {
        this.logOut.next();
    };
    DesktopHeaderComponent.prototype.onSoftLogout = function () {
        this.softLogOut.next();
    };
    DesktopHeaderComponent.prototype.onSubMenuClicked = function (menu) {
        this.onHideSubMenu();
    };
    DesktopHeaderComponent.prototype.onShowSubMenu = function (menu) {
        var _a;
        this.navMenu = tslib_1.__assign({}, this.activeMenus, (_a = {}, _a[menu] = true, _a));
    };
    DesktopHeaderComponent.prototype.onHideSubMenu = function () {
        this.navMenu = tslib_1.__assign({}, this.activeMenus);
    };
    DesktopHeaderComponent.prototype.showLink = function (subMenu) {
        if (!subMenu.route) {
            return false;
        }
        if (subMenu.disable) {
            if (subMenu.waitConditionally) {
                return true;
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    };
    return DesktopHeaderComponent;
}());
export { DesktopHeaderComponent };
