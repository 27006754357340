import { Router, ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var VerificationAuthorizeGuard = /** @class */ (function () {
    function VerificationAuthorizeGuard(router, route) {
        this.router = router;
        this.route = route;
    }
    VerificationAuthorizeGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.canActivate(childRoute, state);
    };
    VerificationAuthorizeGuard.prototype.canActivate = function (route, state) {
        var verificationId = sessionStorage.getItem('verificationId');
        if (verificationId)
            return true;
        this.notAllowEndorse();
        return false;
    };
    VerificationAuthorizeGuard.prototype.notAllowEndorse = function () {
        this.router.navigate(['/eservice/login'], { relativeTo: this.route }); // once we have all the endorsements change it to home
    };
    VerificationAuthorizeGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VerificationAuthorizeGuard_Factory() { return new VerificationAuthorizeGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: VerificationAuthorizeGuard, providedIn: "root" });
    return VerificationAuthorizeGuard;
}());
export { VerificationAuthorizeGuard };
