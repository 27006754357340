import * as i0 from "@angular/core";
var DiscardChangesGuard = /** @class */ (function () {
    function DiscardChangesGuard() {
    }
    DiscardChangesGuard.prototype.canDeactivate = function (component) {
        return component.isSafeToNavigate
            ? component.isSafeToNavigate
            : component.promptDeactivate();
    };
    DiscardChangesGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DiscardChangesGuard_Factory() { return new DiscardChangesGuard(); }, token: DiscardChangesGuard, providedIn: "root" });
    return DiscardChangesGuard;
}());
export { DiscardChangesGuard };
