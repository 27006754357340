import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MfaService } from "../services/mfa.service";
var MfaVerificationComponent = /** @class */ (function () {
    function MfaVerificationComponent(mfaService, router, route) {
        this.mfaService = mfaService;
        this.router = router;
        this.route = route;
        this.cancelled = new EventEmitter();
        this.tokenSubmitted = new EventEmitter();
        this.verificationData = null;
        this.description = 'To proceed with this change, choose one of the following methods of receiving a code for validation:';
        this.isModeSelected = false;
        this.verificationMode = [];
    }
    MfaVerificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.verificationData) {
            this.userPolicyDetailsResp = this.verificationData;
        }
        else {
            var userId = sessionStorage.getItem('userId');
            this.mfaService.getVerification(userId, this.type).subscribe(function (data) {
                if (data) {
                    if (data.verified) {
                        _this.router.navigate(['../login'], {
                            relativeTo: _this.route,
                        });
                    }
                    else {
                        _this.userPolicyDetailsResp = data;
                    }
                }
            }, function (error) {
                _this.router.navigate(['../login'], {
                    relativeTo: _this.route,
                });
            });
        }
    };
    MfaVerificationComponent.prototype.onFormSubmitted = function (value) {
        this.isModeSelected = true;
        this.verificationOptionsForm = value;
    };
    MfaVerificationComponent.prototype.onCancelClicked = function (value) {
        this.isModeSelected = false;
        this.cancelled.emit(value);
    };
    MfaVerificationComponent.prototype.onBackClicked = function (value) {
        this.isModeSelected = false;
        if (value) {
            this.verificationMode.push(value);
        }
    };
    MfaVerificationComponent.prototype.onTokenSubmitted = function (value) {
        this.isModeSelected = true;
        this.tokenSubmitted.emit(tslib_1.__assign({}, value, { type: this.type }));
    };
    return MfaVerificationComponent;
}());
export { MfaVerificationComponent };
