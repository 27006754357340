import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CURRENT_POLICY, USER_ID, WALLET_APP_NAME, CURRENT_POLICY_PAY_PLAN, CURRENT_POLICY_STATUS } from '@prcins/constants';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { DiscardComponent, RightRailService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
var ManagePaymentsComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ManagePaymentsComponent, _super);
    function ManagePaymentsComponent(managePaymentsProviderService, rightRailService, router, route) {
        var _this = _super.call(this) || this;
        _this.managePaymentsProviderService = managePaymentsProviderService;
        _this.rightRailService = rightRailService;
        _this.router = router;
        _this.route = route;
        _this.isLoading = false;
        _this.showSpinner = false;
        _this.showChangePayplanLink = false;
        return _this;
    }
    ManagePaymentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.showSpinner = true;
        this.storedCreditCards = [];
        this.storedBankAccounts = [];
        this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
        this.payPlan = this.parsePayPlan(sessionStorage.getItem(CURRENT_POLICY_PAY_PLAN));
        var currentPolicyStatus = sessionStorage.getItem(CURRENT_POLICY_STATUS);
        var editWalletSuccessParam = sessionStorage.getItem('editWalletSuccess');
        this.editWalletSuccess = editWalletSuccessParam === 'true';
        sessionStorage.removeItem('editWalletSuccess');
        this.managePaymentsProviderService.getPolicyPaymentDetails(sessionStorage.getItem(USER_ID), this.policyNumber, WALLET_APP_NAME).subscribe(function (wallets) {
            _this.isLoading = true;
            if (wallets) {
                wallets.forEach(function (wallet) {
                    if (wallet.paymentType === "CC" || wallet.paymentType === "ATM") {
                        _this.storedCreditCards.push(wallet);
                    }
                    else if (wallet.paymentType === "ACH") {
                        _this.storedBankAccounts.push(wallet);
                    }
                });
            }
            _this.showSpinner = false;
        });
        this.rightRailService.getQuickLinks(this.policyNumber)
            .pipe(map(function (rightRails) {
            if (currentPolicyStatus !== 'Incomplete') {
                var isMrbActive = (rightRails && rightRails.mrbActive === false) ? false : true;
                _this.showChangePayplanLink = isMrbActive || rightRails.showPayPlanChange;
            }
        })).subscribe();
    };
    ManagePaymentsComponent.prototype.parsePayPlan = function (payPlan) {
        return payPlan == null ? "NONE" : payPlan.toUpperCase().includes("EFT") ? "EFT" : payPlan.toUpperCase().includes("REC") ? "REC" : "NONE";
    };
    /* CREDIT CARD PAYMENTS */
    ManagePaymentsComponent.prototype.addNewCard = function (policyNum) {
        this.router.navigate(['../add-card'], {
            queryParams: { policyNumber: policyNum },
            relativeTo: this.route
        });
    };
    ManagePaymentsComponent.prototype.onEditCreditCard = function (card) {
        this.managePaymentsProviderService.data = card;
        if (this.payPlan === "REC") {
            this.payPlanPopup = true;
            this.continueFunction = this.editCreditCard;
        }
        else {
            this.editCreditCard();
        }
    };
    ManagePaymentsComponent.prototype.editCreditCard = function () {
        this.router.navigate(['../edit-card'], {
            queryParams: { policyNumber: this.policyNumber },
            relativeTo: this.route
        });
    };
    ManagePaymentsComponent.prototype.toggleDefault = function (wallet) {
        var _this = this;
        // Call API
        this.managePaymentsProviderService.changeDefaultWallet(sessionStorage.getItem(USER_ID), this.policyNumber, wallet).subscribe(function (response) {
            if (response == null || response.status === 'success' && response.error === "") {
                // Reload
                _this.ngOnInit();
            }
            else {
                alert(response.status + ", " + response.errorMessage);
                // TODO - error
            }
        });
    };
    ManagePaymentsComponent.prototype.onDeleteCreditCard = function (card) {
        this.cardToRemove = card;
        if (this.payPlan === "REC") {
            this.payPlanPopup = true;
            this.continueFunction = this.deleteCreditCard;
        }
        else {
            this.deleteCreditCard();
        }
    };
    ManagePaymentsComponent.prototype.deleteCreditCard = function () {
        this.removeCardPopup = true;
    };
    ManagePaymentsComponent.prototype.doDeleteCard = function (card) {
        this.deleteWallet(card);
        this.removeCardPopup = false;
    };
    ManagePaymentsComponent.prototype.deleteWallet = function (wallet) {
        var _this = this;
        // Call API
        this.managePaymentsProviderService.deleteWallet(sessionStorage.getItem(USER_ID), this.policyNumber, wallet).subscribe(function (response) {
            if (response == null || response.status === 'success' && response.error === "") {
                sessionStorage.setItem('editWalletSuccess', 'true');
                // Reload
                _this.ngOnInit();
            }
            else {
                alert(response.status + ", " + response.errorMessage);
                // TODO - error
            }
        }, function (error) {
            // TODO - show error?
        });
    };
    ManagePaymentsComponent.prototype.goToPayPlan = function () {
        this.router.navigate(['../../billing/change-plan'], {
            relativeTo: this.route
        });
    };
    ManagePaymentsComponent.prototype.continueAction = function () {
        this.payPlanPopup = false;
        this.continueFunction();
    };
    /* BANK PAYMENTS */
    ManagePaymentsComponent.prototype.addNewBank = function (policyNum) {
        this.router.navigate(['../add-bank'], {
            queryParams: { policyNumber: policyNum },
            relativeTo: this.route
        });
    };
    ManagePaymentsComponent.prototype.onEditBank = function (bank) {
        this.managePaymentsProviderService.data = bank;
        if (this.payPlan === "EFT") {
            this.payPlanPopup = true;
            this.continueFunction = this.editBank;
        }
        else {
            this.editBank();
        }
    };
    ManagePaymentsComponent.prototype.editBank = function () {
        this.router.navigate(['../edit-bank'], {
            queryParams: { policyNumber: this.policyNumber },
            relativeTo: this.route
        });
    };
    ManagePaymentsComponent.prototype.onDeleteBank = function (bank) {
        this.bankToRemove = bank;
        if (this.payPlan === "EFT") {
            this.payPlanPopup = true;
            this.continueFunction = this.deleteBank;
        }
        else {
            this.deleteBank();
        }
    };
    ManagePaymentsComponent.prototype.deleteBank = function () {
        this.removeBankPopup = true;
    };
    ManagePaymentsComponent.prototype.doDeleteBank = function (bank) {
        this.deleteWallet(bank);
        this.removeBankPopup = false;
    };
    return ManagePaymentsComponent;
}(DiscardComponent));
export { ManagePaymentsComponent };
