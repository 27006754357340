import { Apollo } from 'apollo-angular';
import { ClaimsSearchQuery } from '@prcins/utils';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var ClaimsSearchService = /** @class */ (function () {
    function ClaimsSearchService(apollo) {
        this.apollo = apollo;
    }
    ClaimsSearchService.prototype.claimsSearchQuery = function (policyNumber) {
        return this.apollo.watchQuery({
            query: ClaimsSearchQuery,
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    };
    ClaimsSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ClaimsSearchService_Factory() { return new ClaimsSearchService(i0.ɵɵinject(i1.Apollo)); }, token: ClaimsSearchService, providedIn: "root" });
    return ClaimsSearchService;
}());
export { ClaimsSearchService };
