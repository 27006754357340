import { Router, ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var AllowEndorseGuard = /** @class */ (function () {
    function AllowEndorseGuard(router, route) {
        this.router = router;
        this.route = route;
    }
    AllowEndorseGuard.prototype.canActivateChild = function (childRoute, state) {
        return this.canActivate(childRoute, state);
    };
    AllowEndorseGuard.prototype.canActivate = function (route, state) {
        var guid = sessionStorage.getItem('guid');
        if (guid)
            return true;
        this.notAllowEndorse();
        return false;
    };
    AllowEndorseGuard.prototype.notAllowEndorse = function () {
        this.router.navigate(['/eservice/home'], { relativeTo: this.route }); // once we have all the endorsements change it to home
    };
    AllowEndorseGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AllowEndorseGuard_Factory() { return new AllowEndorseGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i1.ActivatedRoute)); }, token: AllowEndorseGuard, providedIn: "root" });
    return AllowEndorseGuard;
}());
export { AllowEndorseGuard };
