import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import packageJson from '../../../../../package.json';
import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var SessionService = /** @class */ (function () {
    function SessionService(http, router) {
        this.http = http;
        this.router = router;
        this.version = packageJson.version;
    }
    SessionService.prototype.transferSession = function (target, handler) {
        var URL = environment.AUTH_URL + "/session/redirect";
        var selectedPolicy = sessionStorage.currentPolicy, userId = sessionStorage.userId;
        return this.http.post(URL, { target: target, handler: handler, selectedPolicy: selectedPolicy, userId: userId });
    };
    SessionService.prototype.transferEndorsementSession = function (target, endorsementContext) {
        var URL = environment.AUTH_URL + "/session/redirect";
        var selectedPolicy = sessionStorage.currentPolicy, userId = sessionStorage.userId;
        return this.http.post(URL, { target: target, handler: null, selectedPolicy: selectedPolicy, userId: userId, endorsementContext: endorsementContext });
    };
    SessionService.prototype.hydrateSession = function (session) {
        var URL = environment.AUTH_URL + "/session/restore?session=" + session;
        return this.http.post(URL, {});
    };
    SessionService.prototype.invalidateCacheAfterNewDeploy = function (frequency) {
        var _this = this;
        if (frequency === void 0) { frequency = 1000 * 60 * 5; }
        setInterval(function () {
            if (_this.router.url === '/eservice/login' || _this.router.url === '/eservice/home') {
                console.log(_this.router.url);
                _this.checkVersion('/version/version.json'); //use assets for local
            }
        }, frequency);
    };
    SessionService.prototype.checkVersion = function (url) {
        var _this = this;
        // timestamp these requests to invalidate caches
        this.http.get(url + '?t=' + new Date().getTime())
            .pipe()
            .subscribe(function (response) {
            if (_this.version === response.version) {
                //do nothing
            }
            else {
                _this.clearCache();
                // window.location.reload(true); as passing true deprecated using below line of code
                window.location.href = window.location.href;
            }
        }, function (err) {
            console.error(err, 'Could not get version');
        });
    };
    SessionService.prototype.clearCache = function () {
        var _this = this;
        if ('caches' in window) {
            caches.keys().then(function (names) {
                names.forEach(function (name) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, caches.delete(name)];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); });
            });
        }
    };
    SessionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SessionService_Factory() { return new SessionService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router)); }, token: SessionService, providedIn: "root" });
    return SessionService;
}());
export { SessionService };
