import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var OpenEditCoveragesAmendmentService = /** @class */ (function () {
    // apiUrl = "http://apis.dev.prcaws.net/eservice-ws/v2/";
    function OpenEditCoveragesAmendmentService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.apiUrl = environment.BASE_URL + "/v2/";
    }
    OpenEditCoveragesAmendmentService.prototype.getGUID = function () {
        return sessionStorage.guid;
    };
    OpenEditCoveragesAmendmentService.prototype.recordEndorsmentBlocked = function (type, guid) {
        var policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid ? '&guid=' + guid : ''), {});
    };
    OpenEditCoveragesAmendmentService.prototype.createEndorsement = function (type) {
        var policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.post(this.apiUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
    };
    OpenEditCoveragesAmendmentService.prototype.getEndorsementSummary = function (policyNum, guid) {
        return this.http.get(this.apiUrl + policyNum + '/endorsements/' + guid);
    };
    OpenEditCoveragesAmendmentService.prototype.rateEndorsement = function () {
        var policyNum = sessionStorage.getItem('currentPolicy');
        var guid = sessionStorage.getItem('guid');
        return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/rate', {});
    };
    OpenEditCoveragesAmendmentService.prototype.issueEndorsePolicyV2 = function (policyNum, guid, request) {
        return this.http.put(this.apiUrl + policyNum + '/endorsements/' + guid + '/issue', request)
            .pipe(map(function (response) {
            var error = response.error, payplanLabel = response.billingEndorsements[0].endorsementEntity.endorsementBillingDetails.payplanLabel, fileId = response.summary.metaInfo.fileId;
            return { errorCode: error, fileId: fileId, payplanLabel: payplanLabel };
        }));
    };
    OpenEditCoveragesAmendmentService.prototype.cancelEndorsement = function () {
        var policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
        var guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
        var url = this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid;
        return this.http.delete(url);
    };
    OpenEditCoveragesAmendmentService.prototype.deleteMortgageeEndorsement = function (mortgageeId) {
        var policyNum = sessionStorage.getItem('currentPolicy');
        var guid = sessionStorage.getItem('guid');
        var url = this.apiUrl + policyNum + '/endorsements/' + guid + '/mortgagees/' + mortgageeId;
        return this.http.delete(url);
    };
    OpenEditCoveragesAmendmentService.prototype.endorsementConfig = function (policyNumber) {
        return this.apollo.watchQuery({
            query: AutoEndorsementConfigQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    };
    OpenEditCoveragesAmendmentService.prototype.getEndorsementConfig = function () {
        var policyNum = sessionStorage.getItem('currentPolicy');
        return this.endorsementConfig(policyNum).pipe(map(function (result) { return result.data.getEndorsementResourceConfig; }));
    };
    OpenEditCoveragesAmendmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpenEditCoveragesAmendmentService_Factory() { return new OpenEditCoveragesAmendmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: OpenEditCoveragesAmendmentService, providedIn: "root" });
    return OpenEditCoveragesAmendmentService;
}());
export { OpenEditCoveragesAmendmentService };
export var AutoEndorsementConfigQuery = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\nquery GetEndorsementResourceConfig($policyNumber: String) {\n  getEndorsementResourceConfig(policyNumber: $policyNumber) {\n    addPhysicalDamangeCoverage\n\t  deletePhysicalDamangeCoverage\n\t  addCoverageEnhance\n\t  deleteCoverageEnhance\n  }\n}\n"], ["\nquery GetEndorsementResourceConfig($policyNumber: String) {\n  getEndorsementResourceConfig(policyNumber: $policyNumber) {\n    addPhysicalDamangeCoverage\n\t  deletePhysicalDamangeCoverage\n\t  addCoverageEnhance\n\t  deleteCoverageEnhance\n  }\n}\n"])));
var templateObject_1;
