import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var AiEnrollmentService = /** @class */ (function () {
    function AiEnrollmentService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.invitationApiUrl = environment.BASE_URL + "/registration/v2/invitations";
        this.apiUrl = environment.BASE_URL + "/registration/v2/invitations/user/";
    }
    AiEnrollmentService.prototype.getInvitationDetails = function (id) {
        return this.http.get(this.invitationApiUrl + "?id=" + id);
    };
    AiEnrollmentService.prototype.completeEnrollment = function (enrollmentDetails) {
        return this.http.post(this.apiUrl + 'createAccount', enrollmentDetails);
    };
    AiEnrollmentService.prototype.aiLinkPolicy = function (linkPolicyRequest) {
        return this.http.post(this.apiUrl + 'linkPolicy', linkPolicyRequest);
    };
    AiEnrollmentService.prototype.aiEdocEnrollment = function (edocEnrollmentyRequest) {
        return this.http.post(this.apiUrl + 'edocEnrollment', edocEnrollmentyRequest);
    };
    AiEnrollmentService.prototype.abandonRequest = function (landingPage, id) {
        return this.http.get(this.invitationApiUrl + '/abandon/' + landingPage + '?id=' + id);
    };
    AiEnrollmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AiEnrollmentService_Factory() { return new AiEnrollmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: AiEnrollmentService, providedIn: "root" });
    return AiEnrollmentService;
}());
export { AiEnrollmentService };
