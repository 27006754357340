import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CardFormProviderService, DiscardComponent, FormValidatorService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
var EditCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EditCardComponent, _super);
    function EditCardComponent(cardFormProvider, managePaymentsProviderService, router, formValidator, route) {
        var _this = _super.call(this) || this;
        _this.cardFormProvider = cardFormProvider;
        _this.managePaymentsProviderService = managePaymentsProviderService;
        _this.router = router;
        _this.formValidator = formValidator;
        _this.route = route;
        _this.showSpinner = false;
        _this.showError = false;
        _this.agentInfo = {};
        return _this;
    }
    EditCardComponent.prototype.ngOnInit = function () {
        this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');
        this.cardForm = this.cardFormProvider.getEditCardForm(false);
        this.card = this.managePaymentsProviderService.data;
        this.cardForm.get('cardType').setValue(this.card.cardDisplayType);
        this.cardForm.get('expirationDate').setValue(this.card.expirationDate);
        this.cardForm.get('expirationMonth').setValue(this.card.expirationMonth);
        this.cardForm.get('expirationYear').setValue(this.card.expirationYear);
        this.setAgentInfo();
    };
    EditCardComponent.prototype.doEditCard = function () {
        var _this = this;
        // form Validation
        this.formValidator.validateAllFormFields(this.cardForm);
        if (this.cardForm.valid) {
            this.showSpinner = true;
            var editedCard = this.card; // copy existing card
            editedCard.debitAccount = "*" + editedCard.debitAccount;
            editedCard.cvv = this.cardForm.get('securityCode').value;
            editedCard.expirationDate = null;
            editedCard.expirationMonth = this.cardForm.get('expirationMonth').value;
            editedCard.expirationYear = this.cardForm.get('expirationYear').value;
            editedCard.creditAccount = this.policyNumber;
            editedCard.accountEmail = sessionStorage.getItem(USER_ID);
            editedCard.paymentType = this.card.paymentType;
            editedCard.transType = "CARD";
            editedCard.debitZip = "12345"; // TODO - remove after ACI Speedpay fix
            this.managePaymentsProviderService.editWallet(editedCard).subscribe(function (response) {
                if (response == null || response.status === 'success' && response.error === "") {
                    _this.navigateToManagePayments('true');
                }
                else {
                    _this.showSpinner = false;
                    alert(response.status + ", " + response.errorMessage);
                }
            }, function (error) {
                _this.httpErrorResponse();
            });
        }
    };
    EditCardComponent.prototype.navigateToManagePayments = function (isSuccess) {
        sessionStorage.setItem('editWalletSuccess', isSuccess);
        this.router.navigate(['../manage'], {
            relativeTo: this.route
        });
    };
    EditCardComponent.prototype.cancelTransaction = function () {
        this.navigateToManagePayments('false');
    };
    EditCardComponent.prototype.httpErrorResponse = function () {
        this.showSpinner = false;
        this.showError = true;
    };
    EditCardComponent.prototype.setAgentInfo = function () {
        var agentDetails = sessionStorage.agentInfo;
        if (agentDetails) {
            this.agentInfo = JSON.parse(agentDetails);
        }
        this.generalPhone = sessionStorage.getItem('general-phone');
        this.channel = sessionStorage.channel;
    };
    return EditCardComponent;
}(DiscardComponent));
export { EditCardComponent };
