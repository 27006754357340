import * as tslib_1 from "tslib";
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';
import { Logger } from '../logger/logger';
var LoggingErrorHandler = /** @class */ (function (_super) {
    tslib_1.__extends(LoggingErrorHandler, _super);
    function LoggingErrorHandler(injector) {
        var _this = _super.call(this) || this;
        _this.injector = injector;
        return _this;
    }
    LoggingErrorHandler.prototype.handleError = function (error) {
        _super.prototype.handleError.call(this, error);
        try {
            if (error instanceof Error) {
                this.logStackTrace(error, StackTrace.fromError(error));
            }
            else {
                this.logStackTrace(error, Promise.resolve(['']));
            }
        }
        catch (e) {
            console.error('Error handler threw exception : ', e, 'Error for handling was : ', error);
        }
    };
    LoggingErrorHandler.prototype.logStackTrace = function (error, stack) {
        var _this = this;
        var logger = this.getLogger();
        var errorMessage = this.getErrorMessage(error);
        var url = this.getUrl(error);
        stack.then(function (stackframes) { return Promise.resolve(stackframes.map(function (sf) { return sf.toString(); }).join('\n')); })
            .then(function (stackString) { return logger.error(_this.createLoggerPayload(errorMessage, url, stackString), error); })
            .catch(function (err) { return logger.error('Unable to generate error stack trace : ' + _this.getErrorMessage(err), error); });
    };
    LoggingErrorHandler.prototype.createLoggerPayload = function (errorMessage, url, stackString) {
        if (url === void 0) { url = ''; }
        if (stackString === void 0) { stackString = ''; }
        return {
            url: url,
            message: JSON.stringify({
                errorMessage: errorMessage,
                stackTrace: stackString
            }, null, 0),
        };
    };
    LoggingErrorHandler.prototype.getLogger = function () {
        return this.injector.get(Logger);
    };
    LoggingErrorHandler.prototype.getUrl = function (error) {
        if (error instanceof HttpErrorResponse) {
            return error.url;
        }
        else {
            var location_1 = this.injector.get(LocationStrategy);
            return location_1 instanceof PathLocationStrategy ? location_1.path() : '';
        }
    };
    LoggingErrorHandler.prototype.getErrorMessage = function (error) {
        return error.message ? error.message : error.toString();
    };
    return LoggingErrorHandler;
}(ErrorHandler));
export { LoggingErrorHandler };
