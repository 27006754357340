import { FormGroup, FormControl } from '@angular/forms';
import * as i0 from "@angular/core";
var FormValidatorService = /** @class */ (function () {
    function FormValidatorService() {
    }
    FormValidatorService.prototype.validateAllFormFields = function (formGroup) {
        var _this = this;
        Object.keys(formGroup.controls).forEach(function (field) {
            var control = formGroup.get(field);
            if (control instanceof FormControl && !control.disabled) {
                control.markAsTouched({ onlySelf: true });
            }
            else if (control instanceof FormGroup) {
                _this.validateAllFormFields(control);
            }
        });
    };
    FormValidatorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormValidatorService_Factory() { return new FormValidatorService(); }, token: FormValidatorService, providedIn: "root" });
    return FormValidatorService;
}());
export { FormValidatorService };
