import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TOKEN, tealiumMapper } from '@prcins/constants';
import { map } from 'rxjs/operators';
import { TealiumTrackerService } from '../services/tealium/tealium-tracker.service';
import { Router } from '@angular/router';
import { environment } from '@prcins/environments';
import { UtilityProviderService } from '../services/utility-provider.service';
import { CookieService } from 'ngx-cookie-service';
import { UUID } from 'angular2-uuid';
import * as _ from 'lodash';
import packageJson from '../../../../../package.json';
import * as i0 from "@angular/core";
import * as i1 from "../services/tealium/tealium-tracker.service";
import * as i2 from "@angular/router";
import * as i3 from "../services/utility-provider.service";
import * as i4 from "ngx-cookie-service";
var JwtInterceptor = /** @class */ (function () {
    function JwtInterceptor(tealiumTracker, router, utilityProviderService, cookieService) {
        this.tealiumTracker = tealiumTracker;
        this.router = router;
        this.utilityProviderService = utilityProviderService;
        this.cookieService = cookieService;
        this.version = packageJson.version;
        this.apikeyValue = environment.API_KEY_VALUE;
    }
    JwtInterceptor.prototype.intercept = function (request, next) {
        var _this = this;
        var token = sessionStorage.getItem(TOKEN);
        console.log(request.url);
        if (!token && _.includes(request.url, 'endorsement/abandon')) {
            token = sessionStorage.getItem('splToken');
        }
        if (token && token.length > 1) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Bearer " + token,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache',
                    'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
                    'x-traceid': UUID.UUID(),
                    "ui-version": this.version,
                    "api-version": this.apikeyValue
                }
            });
        }
        else {
            request = request.clone({
                setHeaders: {
                    'x-traceid': UUID.UUID(),
                    "ui-version": this.version,
                    "api-version": this.apikeyValue
                }
            });
        }
        return next.handle(request).pipe(map(function (event) {
            var startIndex = environment.BASE_URL.length + 1;
            var url = request.url.substring(startIndex);
            if (event instanceof HttpResponse) {
                if (_.includes(request.url, 'eservice-ws') && event.body && event.body.errors) {
                    var errors = event.body.errors;
                    if (errors.find(function (error) { return error['message'] === 'Unauthorized'; })) {
                        _this.utilityProviderService.clearAppStorage();
                        _this.utilityProviderService.clearCookies();
                        _this.utilityProviderService.unauthorized$.next(true);
                        return;
                    }
                }
                else if (event.status === 401) {
                    _this.utilityProviderService.clearAppStorage();
                    _this.utilityProviderService.clearCookies();
                    _this.utilityProviderService.unauthorized$.next(true);
                    return;
                }
                var mapping = tealiumMapper.get(url);
                if (mapping) {
                    for (var key in mapping.predicate) {
                        if (event.body[key] == mapping.predicate[key]) {
                            console.log("Tracking " + url + " :: " + key + "=" + mapping.predicate[key]);
                            _this.processPayload(mapping.payload.if, request.body, event.body);
                        }
                        else {
                            console.log("Tracking " + url + " :: " + key + "!=" + mapping.predicate[key]);
                            _this.processPayload(mapping.payload.else, request.body, event.body);
                        }
                    }
                }
            }
            return event;
        }));
    };
    JwtInterceptor.prototype.processPayload = function (payloadTemplate, request, response) {
        var eVars = payloadTemplate.eVars, eventName = payloadTemplate.eventName;
        var payload = this.tealiumTracker.pageContext;
        var eVarPayload = {};
        var eventsPayload = {};
        if (eVars && eVars.length > 0) {
            for (var _i = 0, eVars_1 = eVars; _i < eVars_1.length; _i++) {
                var eVar = eVars_1[_i];
                var eVarName = Object.keys(eVar)[0];
                var eVarPath = Object.values(eVar)[0].toString();
                var _a = eVarPath.split(':'), eVarPathPrefix = _a[0], eVarPathVal = _a[1];
                if (eVarPathPrefix == 'request') {
                    if (request[eVarPathVal]) {
                        eVarPayload[eVarName] = request[eVarPathVal];
                    }
                }
                else {
                    if (response[eVarPathVal]) {
                        eVarPayload[eVarName] = request[eVarPathVal];
                    }
                }
            }
        }
        if (eventName) {
            eventsPayload = { event_name: eventName };
        }
        Object.assign(payload, eVarPayload, eventsPayload);
        this.tealiumTracker.link(payload);
    };
    JwtInterceptor.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function JwtInterceptor_Factory() { return new JwtInterceptor(i0.ɵɵinject(i1.TealiumTrackerService), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.UtilityProviderService), i0.ɵɵinject(i4.CookieService)); }, token: JwtInterceptor, providedIn: "root" });
    return JwtInterceptor;
}());
export { JwtInterceptor };
