import * as tslib_1 from "tslib";
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var OpenVehicleAmendmentService = /** @class */ (function () {
    // apiUrl = "http://apis.dev.prcaws.net/eservice-ws/v2/";
    function OpenVehicleAmendmentService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.apiUrl = environment.BASE_URL + "/v2/";
    }
    OpenVehicleAmendmentService.prototype.getCurrentPolicyInfo = function () {
        return sessionStorage.currentPolicy;
    };
    OpenVehicleAmendmentService.prototype.getGUID = function () {
        return sessionStorage.guid;
    };
    OpenVehicleAmendmentService.prototype.getActionTypeInfo = function () {
        return sessionStorage.actionType;
    };
    OpenVehicleAmendmentService.prototype.endorsementConfig = function (policyNumber) {
        return this.apollo.watchQuery({
            query: VehicleEndorsementConfigQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    };
    OpenVehicleAmendmentService.prototype.getEndorsementConfig = function (policyNumber) {
        return this.endorsementConfig(policyNumber).pipe(map(function (result) { return result.data.getEndorsementResourceConfig; }));
    };
    OpenVehicleAmendmentService.prototype.recordEndorsmentBlocked = function (type, guid) {
        var policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid ? '&guid=' + guid : ''), {});
    };
    OpenVehicleAmendmentService.prototype.createEndorsement = function (type) {
        var policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.post(this.apiUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
    };
    OpenVehicleAmendmentService.prototype.cancelEndorsement = function () {
        var policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
        var guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
        var url = this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid;
        return this.http.delete(url);
    };
    OpenVehicleAmendmentService.prototype.rateEndorsement = function () {
        var policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : '';
        var guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : '';
        return this.http.put(this.apiUrl + 'core/' + policyNum + '/endorsements/' + guid + '/rate', {});
    };
    OpenVehicleAmendmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpenVehicleAmendmentService_Factory() { return new OpenVehicleAmendmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: OpenVehicleAmendmentService, providedIn: "root" });
    return OpenVehicleAmendmentService;
}());
export { OpenVehicleAmendmentService };
export var VehicleEndorsementConfigQuery = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\nquery GetEndorsementResourceConfig($policyNumber: String) {\n  getEndorsementResourceConfig(policyNumber: $policyNumber) {\n    addVehicle\n    updateVehicle\n    deleteVehicle\n    replaceVehicle\n }\n}\n"], ["\nquery GetEndorsementResourceConfig($policyNumber: String) {\n  getEndorsementResourceConfig(policyNumber: $policyNumber) {\n    addVehicle\n    updateVehicle\n    deleteVehicle\n    replaceVehicle\n }\n}\n"])));
var templateObject_1;
