import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import { Observable, BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var RegistrationService = /** @class */ (function () {
    function RegistrationService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.userRegistrationForm$ = new BehaviorSubject({});
        this.userRegistrationFormObservable = this.userRegistrationForm$.asObservable();
        this.verifyUserRegisteredOrNot$ = new BehaviorSubject({});
        this.verifyUserRegisteredOrNotObservable = this.verifyUserRegisteredOrNot$.asObservable();
        this.apiUrl = environment.BASE_URL + "/registration/v2/account/";
    }
    RegistrationService.prototype.getPasswordActionTypeInfo = function () {
        return sessionStorage.passwordActionType;
    };
    RegistrationService.prototype.getUserDetails = function (verificationId) {
        return this.http.get(this.apiUrl + 'user/verify/' + verificationId);
    };
    RegistrationService.prototype.userDetails = function (details) {
        return this.http.post(this.apiUrl + 'user/verify', details);
    };
    RegistrationService.prototype.selectValidationMethod = function (mode) {
        return this.http.post(this.apiUrl + 'token', mode);
    };
    RegistrationService.prototype.verifyAuthorizationCode = function (code) {
        return this.http.post(this.apiUrl + 'token/verify', code);
    };
    RegistrationService.prototype.forgotEmail = function (account) {
        return this.http.post(this.apiUrl + 'user/forgotEmail', account);
    };
    RegistrationService.prototype.forgotPassword = function (info) {
        return this.http.post(this.apiUrl + 'user/forgotPassword', info);
    };
    RegistrationService.prototype.changePassword = function (info) {
        return this.http.post(this.apiUrl + 'user/changePassword', info);
    };
    RegistrationService.prototype.createAccount = function (account) {
        return this.http.post(this.apiUrl + 'user/createAccount', account);
    };
    RegistrationService.prototype.linkPolicy = function (linkPolicyRequest) {
        return this.http.post(this.apiUrl + 'user/linkPolicy', linkPolicyRequest);
    };
    RegistrationService.prototype.checkIfPolicyAlreadyRegisteredInEservice = function (policyNumber) {
        return this.http.get(this.apiUrl + 'user/checkPolicyRegistered/' + policyNumber);
    };
    // {
    //   "billingTextAlertPhoneNumber": "4012980927",
    //   "dob": "1983-01-16",
    //   "enrollBillingTextAlert": true,
    //   "enrollEDOC": true,
    //   "password": "test1test",
    //   "policyNumber": "PRA00008096323",
    //   "userName": "htandon@plymouthrock.com",
    //   "zipCode": "02191"
    // }
    RegistrationService.prototype.createQuickPayAccount = function (newAccount) {
        return this.http.post(this.apiUrl + 'user/quickPay/createAccount', newAccount);
    };
    RegistrationService.prototype.invalidateEndorsementSession = function () {
        sessionStorage.removeItem('verificationId');
        sessionStorage.removeItem('passwordActionType');
        sessionStorage.removeItem('origin');
    };
    RegistrationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RegistrationService_Factory() { return new RegistrationService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: RegistrationService, providedIn: "root" });
    return RegistrationService;
}());
export { RegistrationService };
