import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
export var FuturePaymentQuery = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  query BillingDetails($policyNumber: String) {\n    billingDetails(policyNumber: $policyNumber) {\n      serviceCharge\n      isNewEftServiceFee\n      futureInstallments {\n        transactionDt\n        transactionAmnt\n      }\n    }\n  }\n"], ["\n  query BillingDetails($policyNumber: String) {\n    billingDetails(policyNumber: $policyNumber) {\n      serviceCharge\n      isNewEftServiceFee\n      futureInstallments {\n        transactionDt\n        transactionAmnt\n      }\n    }\n  }\n"])));
export var PastPaymentQuery = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  query BillingDetails($policyNumber: String) {\n    billingDetails(policyNumber: $policyNumber) {\n      paymentHistory {\n        transactionDt\n        transactionAmnt\n        transactionType\n      }\n    }\n  }\n"], ["\n  query BillingDetails($policyNumber: String) {\n    billingDetails(policyNumber: $policyNumber) {\n      paymentHistory {\n        transactionDt\n        transactionAmnt\n        transactionType\n      }\n    }\n  }\n"])));
export var BillingDetailsQuery = gql(templateObject_3 || (templateObject_3 = tslib_1.__makeTemplateObject(["\n  query BillingDetails($policyNumber: String) {\n    billingDetails(policyNumber: $policyNumber) {\n      balance\n      nextPaymentAmnt\n      nextPaymentDueDt\n      pastDueAmnt\n      lastPaymentAmnt\n      lastPaymentDt\n      payPlanLabel\n      payplan\n      effectiveDate\n      expiryDate\n      messageCode\n      payPlanInstallment\n    }\n  }\n"], ["\n  query BillingDetails($policyNumber: String) {\n    billingDetails(policyNumber: $policyNumber) {\n      balance\n      nextPaymentAmnt\n      nextPaymentDueDt\n      pastDueAmnt\n      lastPaymentAmnt\n      lastPaymentDt\n      payPlanLabel\n      payplan\n      effectiveDate\n      expiryDate\n      messageCode\n      payPlanInstallment\n    }\n  }\n"])));
export var BillingInstallmentsQuery = gql(templateObject_4 || (templateObject_4 = tslib_1.__makeTemplateObject(["\n  query Installments($policyNumber: String) {\n    billingInstallments(policyNumber: $policyNumber) {\n      code\n      eftOnly\n      orderby\n      label\n      recPayOnly\n      payMethod{\n        payplan\n        payMethod\n        payHelpMessage\n      }\n      priorCarrier\n    }\n    billingDetails(policyNumber: $policyNumber) {\n      payplan\n      payPlanInstallment\n      payPlanLabel\n      payPlanEditable\n      paymentPendingSettlememt\n      nextPaymentDueDt\n      nextPaymentAmnt\n    }\n    policyDetails(policyNumber: $policyNumber) {\n      product {\n        state\n        companyCode\n        productCode\n        effectiveDate\n      }\n      twoPayEligible\n\t    twoPayDiscountApplied\n    }\n  }\n"], ["\n  query Installments($policyNumber: String) {\n    billingInstallments(policyNumber: $policyNumber) {\n      code\n      eftOnly\n      orderby\n      label\n      recPayOnly\n      payMethod{\n        payplan\n        payMethod\n        payHelpMessage\n      }\n      priorCarrier\n    }\n    billingDetails(policyNumber: $policyNumber) {\n      payplan\n      payPlanInstallment\n      payPlanLabel\n      payPlanEditable\n      paymentPendingSettlememt\n      nextPaymentDueDt\n      nextPaymentAmnt\n    }\n    policyDetails(policyNumber: $policyNumber) {\n      product {\n        state\n        companyCode\n        productCode\n        effectiveDate\n      }\n      twoPayEligible\n\t    twoPayDiscountApplied\n    }\n  }\n"])));
export var HomeEndorsementControlAgentQuery = gql(templateObject_5 || (templateObject_5 = tslib_1.__makeTemplateObject(["\n  query HomeEndorsementControlAgent($policyNumber: String) {\n    homeEndorsementControlAgent(policyNumber:$policyNumber) {\n      editMortgagee\n      deleteMortgagee\n    }\n  }\n"], ["\n  query HomeEndorsementControlAgent($policyNumber: String) {\n    homeEndorsementControlAgent(policyNumber:$policyNumber) {\n      editMortgagee\n      deleteMortgagee\n    }\n  }\n"])));
export var BillingEftQuery = gql(templateObject_6 || (templateObject_6 = tslib_1.__makeTemplateObject(["\n  query BillingEftData($policyNumber: String, $payPlan: String) {\n    billingEftData(policyNumber: $policyNumber, payplan: $payPlan) {\n      messageCode\n      eftAccountNumber\n      eftRoutingNumber\n      eftAccountType\n      payplan\n    }\n  }\n"], ["\n  query BillingEftData($policyNumber: String, $payPlan: String) {\n    billingEftData(policyNumber: $policyNumber, payplan: $payPlan) {\n      messageCode\n      eftAccountNumber\n      eftRoutingNumber\n      eftAccountType\n      payplan\n    }\n  }\n"])));
export var BillingCCQuery = gql(templateObject_7 || (templateObject_7 = tslib_1.__makeTemplateObject(["\n  query BillingCCData($policyNumber: String, $payPlan: String) {\n    billingCreditCardData(policyNumber: $policyNumber, payplan: $payPlan) {\n      ccType\n      payplan\n      ccAccountNumber\n      expDate\n    }\n  }\n"], ["\n  query BillingCCData($policyNumber: String, $payPlan: String) {\n    billingCreditCardData(policyNumber: $policyNumber, payplan: $payPlan) {\n      ccType\n      payplan\n      ccAccountNumber\n      expDate\n    }\n  }\n"])));
export var BalanceDetailsQuery = gql(templateObject_8 || (templateObject_8 = tslib_1.__makeTemplateObject(["\n  query BalanceDetails($policyNumber: String) {\n    balanceDetails(policyNumber: $policyNumber) {\n      pastTermBalance\n      currentTermBalance\n      futureTermBalance\n    }\n  }\n"], ["\n  query BalanceDetails($policyNumber: String) {\n    balanceDetails(policyNumber: $policyNumber) {\n      pastTermBalance\n      currentTermBalance\n      futureTermBalance\n    }\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8;
