import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import gql from 'graphql-tag';
import { PaymentDetailsQuery, PolicyReviewQuery, MobileRegistrationQuery, MobileRegisteredPolicyQuery, BillingEftQuery } from '@prcins/utils';
import { PAYMENT_URL, QPAY_PAYMENT_DETAILS, REGISTRATION_URL } from '@prcins/constants';
import { LOGIN_URL } from '@prcins/constants';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var PaymentProviderService = /** @class */ (function () {
    function PaymentProviderService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.APP_NAME = 'qpay';
        this._ACHValidationFailureCount = 0;
    }
    PaymentProviderService.prototype.showRegistrationWidget = function (payload) {
        return this.http.post(REGISTRATION_URL.Enrollment, payload);
    };
    PaymentProviderService.prototype.stepOneRegistration = function (payload) {
        return this.http.post(REGISTRATION_URL.MobileVerification, payload);
    };
    PaymentProviderService.prototype.stepTwoRegistration = function (payload) {
        return this.http.post(REGISTRATION_URL.MobileSecurityCodeVerification, payload);
    };
    PaymentProviderService.prototype.eDiscloserAudit = function (payload) {
        return this.http.post(LOGIN_URL.AuditUrl, payload).pipe(catchError(function (error) {
            return throwError(error);
        }));
    };
    PaymentProviderService.prototype.getPayloadForRegistration = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: MobileRegistrationQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PaymentProviderService.prototype.getPaymentDetails = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PaymentDetailsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PaymentProviderService.prototype.encryptPaymentDetails = function (paymentDetails, policyNumber) {
        return this.http.post(PAYMENT_URL.Secure, tslib_1.__assign({}, paymentDetails, { policyNumber: policyNumber }));
    };
    PaymentProviderService.prototype.validateCard = function (cardValidationPayload, underwriterCode) {
        var validateUrl = PAYMENT_URL.ValidateCard + "/" + cardValidationPayload.policyNumber + "/" + underwriterCode + "/" + this.APP_NAME;
        return this.http.post(validateUrl, cardValidationPayload);
    };
    PaymentProviderService.prototype.setPaymentDetails = function (paymentDetails) {
        if (paymentDetails.paymentOptionSelected === 'cc') {
            paymentDetails.cardForm['__typename'] = 'CardDetails';
            paymentDetails.eftForm = {
                __typename: 'AccountDetails',
                accountEmailId: '',
                accountHolderName: '',
                accountType: '%%',
                accountNumber: '',
                reAccountNumber: '',
                routingNumber: '',
                maskedAccountNumber: '',
                maskedReAccountNumber: '',
                maskedRoutingNumber: ''
            };
        }
        else {
            paymentDetails.eftForm['__typename'] = 'AccountDetails';
            paymentDetails.cardForm = {
                __typename: 'CardDetails',
                cardHolderName: '',
                cardHolderEmailId: '',
                cardNumber: '',
                securityCode: '',
                transactionType: '%%',
                maskedCardNumber: '',
                expirationDate: ''
            };
        }
        this.apollo.getClient().writeData({
            data: {
                paymentDetails: tslib_1.__assign({}, paymentDetails, { __typename: 'PaymentDetails' })
            }
        });
    };
    PaymentProviderService.prototype.getPaymentDetailsFromSession = function (policyNumber) {
        var pd = sessionStorage.getItem(QPAY_PAYMENT_DETAILS);
        if (pd && policyNumber) {
            return this.http.post(PAYMENT_URL.Decrypt, {
                pd: pd,
                policyNumber: policyNumber
            });
        }
        return of(null);
    };
    PaymentProviderService.prototype.getPolicyPaymentDetails = function () {
        try {
            return this.apollo.getClient().readQuery({
                query: gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n          {\n            paymentDetails {\n              paymentAmount\n              paymentOptionSelected\n              cardForm {\n                cardHolderName\n                cardHolderEmailId\n                transactionType\n                cardNumber\n                maskedCardNumber\n                securityCode\n                expirationDate\n              }\n              eftForm {\n                accountEmailId\n                accountHolderName\n                accountType\n                accountNumber\n                reAccountNumber\n                routingNumber\n                maskedAccountNumber\n                maskedReAccountNumber\n                maskedRoutingNumber\n                etfSavedPaymentMethod\n              }\n            }\n          }\n        "], ["\n          {\n            paymentDetails {\n              paymentAmount\n              paymentOptionSelected\n              cardForm {\n                cardHolderName\n                cardHolderEmailId\n                transactionType\n                cardNumber\n                maskedCardNumber\n                securityCode\n                expirationDate\n              }\n              eftForm {\n                accountEmailId\n                accountHolderName\n                accountType\n                accountNumber\n                reAccountNumber\n                routingNumber\n                maskedAccountNumber\n                maskedReAccountNumber\n                maskedRoutingNumber\n                etfSavedPaymentMethod\n              }\n            }\n          }\n        "])))
            });
        }
        catch (ex) {
            return {};
        }
    };
    PaymentProviderService.prototype.getPolicyReviewDetails = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyReviewQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PaymentProviderService.prototype.getMobileRegisteredPolicies = function (username) {
        return this.apollo
            .watchQuery({
            query: MobileRegisteredPolicyQuery,
            variables: {
                username: username
            },
            fetchPolicy: 'no-cache'
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.account.registeredPolicies;
        }));
    };
    PaymentProviderService.prototype.makePayment = function (type, payload) {
        var url = PAYMENT_URL.Payment + "/" + type;
        return this.http.post(url, payload);
    };
    PaymentProviderService.prototype.getPendingPaymentsDetails = function (policyNumber) {
        return this.http.get(PAYMENT_URL.checkPendingPayments + '/' + policyNumber);
    };
    PaymentProviderService.prototype.getEFTDetails = function (policyNumber, payPlan) {
        return this.apollo
            .watchQuery({
            query: BillingEftQuery,
            variables: {
                policyNumber: policyNumber,
                payPlan: payPlan
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.billingEftData;
        }));
    };
    PaymentProviderService.prototype.resetACHFailureCount = function () {
        this._ACHValidationFailureCount = 0;
    };
    PaymentProviderService.prototype.incrementACHFailureCount = function () {
        this._ACHValidationFailureCount++;
    };
    PaymentProviderService.prototype.getACHFailureCount = function () {
        return this._ACHValidationFailureCount;
    };
    PaymentProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PaymentProviderService_Factory() { return new PaymentProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: PaymentProviderService, providedIn: "root" });
    return PaymentProviderService;
}());
export { PaymentProviderService };
var templateObject_1;
