import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CardFormProviderService, DiscardComponent, FormValidatorService } from '@prcins/utils';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
import { Card } from '../model/wallet';
var AddCardComponent = /** @class */ (function (_super) {
    tslib_1.__extends(AddCardComponent, _super);
    function AddCardComponent(cardFormProvider, managePaymentsProviderService, router, route, formValidator) {
        var _this = _super.call(this) || this;
        _this.cardFormProvider = cardFormProvider;
        _this.managePaymentsProviderService = managePaymentsProviderService;
        _this.router = router;
        _this.route = route;
        _this.formValidator = formValidator;
        _this.showSpinner = false;
        _this.showError = false;
        _this.agentInfo = {};
        return _this;
    }
    AddCardComponent.prototype.ngOnInit = function () {
        this.cardForm = this.cardFormProvider.getForm(false);
        // Add CVV
        this.cardForm.controls.securityCode = new FormControl('', [Validators.required, Validators.minLength(3)]);
        this.cardForm.controls.maskedSecurityCode = new FormControl('', [Validators.required, Validators.minLength(3)]);
        this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');
        this.setAgentInfo();
    };
    AddCardComponent.prototype.doAddCard = function () {
        var _this = this;
        // form Validation
        this.formValidator.validateAllFormFields(this.cardForm);
        if (this.cardForm.valid) {
            this.showSpinner = true;
            var newCard = new Card();
            newCard.debitAccount = this.cardForm.get('cardNumber').value;
            newCard.cvv = this.cardForm.get('securityCode').value;
            newCard.expirationMonth = this.cardForm.get('expirationMonth').value;
            newCard.expirationYear = this.cardForm.get('expirationYear').value;
            newCard.creditAccount = this.policyNumber;
            newCard.accountEmail = sessionStorage.getItem(USER_ID);
            newCard.paymentType = "CC";
            newCard.transType = "CARD";
            newCard.accountNickName = new Date().getTime() + " : " + newCard.debitAccount; // TODO - remove after ACI Speedpay fix
            newCard.debitZip = "12345"; // TODO - remove after ACI Speedpay fix
            // Call HTTP request for the API
            this.managePaymentsProviderService.createWallet(newCard).subscribe(function (response) {
                if (response == null || response.status === 'success' && response.error === "") {
                    _this.showSpinner = false;
                    _this.navigateToManagePayments('true');
                }
                else {
                    _this.showSpinner = false;
                    alert(response.status + ", " + response.errorMessage);
                }
            }, function (error) {
                _this.httpErrorResponse();
            });
        }
    };
    AddCardComponent.prototype.navigateToManagePayments = function (isSuccess) {
        sessionStorage.setItem('editWalletSuccess', isSuccess);
        this.router.navigate(['../manage'], {
            relativeTo: this.route
        });
    };
    AddCardComponent.prototype.cancelTransaction = function () {
        this.navigateToManagePayments('false');
    };
    AddCardComponent.prototype.httpErrorResponse = function () {
        this.showSpinner = false;
        this.showError = true;
    };
    AddCardComponent.prototype.setAgentInfo = function () {
        var agentDetails = sessionStorage.agentInfo;
        if (agentDetails) {
            this.agentInfo = JSON.parse(agentDetails);
        }
        this.generalPhone = sessionStorage.getItem('general-phone');
        this.channel = sessionStorage.channel;
    };
    return AddCardComponent;
}(DiscardComponent));
export { AddCardComponent };
