import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var DropdownService = /** @class */ (function () {
    function DropdownService(http) {
        this.http = http;
        this.baseURL = sessionStorage.getItem('baseURL');
        this.apiUrlGetDrivers = this.baseURL + "/dropdown/driver/list";
        this.apiUrl = this.baseURL + "/dropdown/lists";
    }
    DropdownService.prototype.getDropdownEntries = function (request) {
        return this.http.post(this.apiUrl, request);
    };
    DropdownService.prototype.getDriverDropdownEntries = function (request) {
        return this.http.post(this.apiUrlGetDrivers, request);
    };
    DropdownService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DropdownService_Factory() { return new DropdownService(i0.ɵɵinject(i1.HttpClient)); }, token: DropdownService, providedIn: "root" });
    return DropdownService;
}());
export { DropdownService };
export var DropDownName;
(function (DropDownName) {
    DropDownName["VEH_PRIMARY_USE"] = "VEH_PRIMARY_USE";
    DropDownName["ANTI_THFT_DEV"] = "ANTI_THFT_DEV";
    DropDownName["PRE_INSP_REQ"] = "PRE_INSP_REQ";
    DropDownName["OWNER_STATUS"] = "OWNER_STATUS";
    DropDownName["LOAN_OR_LEASE"] = "LOAN_OR_LEASE";
    DropDownName["US_STATE"] = "US_STATE";
})(DropDownName || (DropDownName = {}));
