import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { DiscardComponent } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidatorService, RegistrationService, UtilityProviderService } from '@prcins/utils';
import { USER_ID, USER_LOGIN, TOKEN } from '@prcins/constants';
var CreatePasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CreatePasswordComponent, _super);
    function CreatePasswordComponent(fb, router, route, formValidator, utilityProvider, registrationProvider) {
        var _this = _super.call(this) || this;
        _this.fb = fb;
        _this.router = router;
        _this.route = route;
        _this.formValidator = formValidator;
        _this.utilityProvider = utilityProvider;
        _this.registrationProvider = registrationProvider;
        _this.showSpinner = false;
        _this.alreadyRegisteredAccountPopup = false;
        _this.registrationError = false;
        _this.createPasswordForm = _this.utilityProvider.getCreatePasswordForm();
        _this.showCreatePasswordForm = false;
        return _this;
    }
    CreatePasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.verificationId = sessionStorage.getItem('verificationId');
        if (this.verificationId) {
            this.updateSafeToNavigate(false);
        }
        this.registrationProvider.getUserDetails(this.verificationId).subscribe(function (data) {
            if (data && data.edocEnrollmentAllowed) {
                _this.createPasswordForm.addControl('enrollEDOC', new FormControl('', [Validators.required]));
            }
            _this.showCreatePasswordForm = true;
        }, function (error) {
            _this.registrationProvider.invalidateEndorsementSession();
            _this.router.navigate(['../login'], {
                relativeTo: _this.route,
            });
        });
    };
    CreatePasswordComponent.prototype.onSubmit = function () {
        var _this = this;
        if (this.createPasswordForm.valid) {
            this.showSpinner = true;
            var formValue_1 = this.createPasswordForm.value;
            formValue_1.verificationId = this.verificationId;
            if (formValue_1.enrollEDOC === 'Y') {
                formValue_1.enrollEDOC = true;
            }
            else if (formValue_1.enrollEDOC === 'N') {
                formValue_1.enrollEDOC = false;
            }
            else {
                formValue_1.enrollEDOC = null;
            }
            delete formValue_1.confirmPassword;
            this.registrationProvider
                .createAccount(formValue_1)
                .subscribe(function (resp) {
                if (resp.messageCode === 'REGISTRATION_SUCCESS') {
                    resp.userId = formValue_1.userName;
                    sessionStorage.setItem(USER_LOGIN, 'Y');
                    sessionStorage.setItem(USER_ID, formValue_1.userName);
                    sessionStorage.setItem(TOKEN, resp.token);
                    sessionStorage.setItem('randomNumber', Math.random().toString());
                    _this.registrationProvider.invalidateEndorsementSession();
                    _this.registrationProvider.userRegistrationForm$.next(null);
                    _this.updateSafeToNavigate(true);
                    _this.showSpinner = false;
                    var routePath = ['eservice/home'];
                    _this.router.navigate(routePath);
                }
                else if (resp.messageCode === 'ACCOUNT_EXISTS') {
                    _this.alreadyRegisteredAccountPopup = true;
                }
                else if (resp.messageCode === 'PASSWORD_NOT_UPDATED' || resp.messageCode === 'PASSWORD_INVALID' || resp.messageCode === 'REGISTRATION_UNSUCCESS' || resp.messageCode === 'POLICY_ALREADY_REGISTERED') {
                    _this.registrationError = true;
                    _this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
                }
                _this.showSpinner = false;
            }, function (error) {
                _this.showSpinner = false;
                _this.registrationError = true;
                _this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
            });
        }
        else {
            this.formValidator.validateAllFormFields(this.createPasswordForm);
        }
    };
    CreatePasswordComponent.prototype.registeredAccount = function (event) {
        if (event.value) {
            this.registeredAccountAlreadyExists = event.value;
        }
    };
    CreatePasswordComponent.prototype.onContinue = function () {
        if (this.registeredAccountAlreadyExists === 'EXISTING') {
            this.updateSafeToNavigate(true);
            this.router.navigate(['../login'], {
                relativeTo: this.route,
                state: {
                    email: this.createPasswordForm.value.userName
                }
            });
        }
        else if (this.registeredAccountAlreadyExists === 'NEW_POLICY') {
            this.updateSafeToNavigate(true);
            this.router.navigate(['/eservice/link-policies'], {
                relativeTo: this.route,
                state: {
                    email: this.createPasswordForm.value.userName
                }
            });
        }
    };
    CreatePasswordComponent.prototype.cancelTransaction = function () {
        this.registrationProvider.invalidateEndorsementSession();
        // this.chooseDeactivate(true);
        this.updateSafeToNavigate(true);
        this.router.navigate(['../login'], {
            relativeTo: this.route,
        });
    };
    return CreatePasswordComponent;
}(DiscardComponent));
export { CreatePasswordComponent };
