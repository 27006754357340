import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
var BankFormComponent = /** @class */ (function () {
    function BankFormComponent(route) {
        this.route = route;
        this.isChangePayPlan = false;
        this.maskedControls = {
            1: '************',
            2: '**'
        };
        this.helps = {
            accountNumber: false,
            routingNumber: false
        };
        this.helpFlags = tslib_1.__assign({}, this.helps);
        this.checkingSelectedCls = '';
        this.savingsSelectedCls = '';
        this.accountMismatchError = false;
        this.routingError = false;
    }
    BankFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        var routePath = this.route.snapshot.routeConfig.path;
        if (routePath === 'change-plan') {
            this.isChangePayPlan = true;
            this.eftForm.addControl('plan_terms', new FormControl(false, [Validators.requiredTrue]));
        }
        var reAccountValue;
        if (this.eftForm.get('accountType').value === 'C') {
            this.checkingSelected();
        }
        else if (this.eftForm.get('accountType').value === 'S') {
            this.savingSelected();
        }
        this.eftForm.get("maskedAccountNumber").valueChanges.subscribe(function (val) {
            if (val && !val.includes('*')) {
                _this.eftForm.get('accountNumber').setValue(val);
                reAccountValue = _this.eftForm.get('reAccountNumber').value;
                if (reAccountValue && val !== reAccountValue) {
                    _this.accountMismatchError = true;
                }
                else {
                    _this.accountMismatchError = false;
                }
            }
        });
        this.eftForm.get("maskedReAccountNumber").valueChanges.subscribe(function (val) {
            if (val && !val.includes('*')) {
                _this.eftForm.get('reAccountNumber').setValue(val);
                if (val !== _this.eftForm.get('accountNumber').value) {
                    _this.accountMismatchError = true;
                }
                else {
                    _this.accountMismatchError = false;
                }
            }
        });
        this.eftForm.get("maskedRoutingNumber").valueChanges.subscribe(function (val) {
            if (val && !val.includes('*')) {
                _this.eftForm.get('routingNumber').setValue(val);
                _this.eftForm.get('routingNumber').markAsTouched();
                setTimeout(function () {
                    if (!_this.eftForm.get('routingNumber').valid) {
                        _this.routingError = true;
                    }
                    else {
                        _this.routingError = false;
                    }
                }, 0);
            }
        });
    };
    BankFormComponent.prototype.checkingSelected = function () {
        this.savingsSelectedCls = 'bg-white text-black';
        this.checkingSelectedCls = 'bg-es-blue text-white';
        this.eftForm.patchValue({ accountType: 'C' });
    };
    BankFormComponent.prototype.savingSelected = function () {
        this.checkingSelectedCls = 'bg-white text-black';
        this.savingsSelectedCls = 'bg-es-blue text-white';
        this.eftForm.patchValue({ accountType: 'S' });
    };
    BankFormComponent.prototype.handleMaskOnBlur = function (maskedControlName, actualControlName, maskType) {
        if (maskType === void 0) { maskType = 1; }
        var maskedControl = this.eftForm.get(maskedControlName);
        var actualControl = this.eftForm.get(actualControlName);
        var actualValue = maskedControl.value;
        actualControl.markAsTouched();
        if (actualValue.length >= 4) {
            maskedControl.setValue(this.maskedControls[maskType] + actualValue.slice(-4));
        }
    };
    BankFormComponent.prototype.handleMaskOnFocus = function (maskedControlName, actualControlName) {
        var maskedControl = this.eftForm.get(maskedControlName);
        var actualControl = this.eftForm.get(actualControlName);
        maskedControl.setValue(actualControl.value);
        delete history.state.error;
        this.eftForm.updateValueAndValidity();
    };
    BankFormComponent.prototype.showHelpText = function (key) {
        this.helpFlags = tslib_1.__assign({}, this.helps);
        this.helpFlags[key] = true;
    };
    BankFormComponent.prototype.hideHelpText = function () {
        this.helpFlags = tslib_1.__assign({}, this.helps);
    };
    BankFormComponent.prototype.onTermsChanged = function (item) {
        this.eftForm.get('plan_terms').setValue(item.value);
    };
    return BankFormComponent;
}());
export { BankFormComponent };
