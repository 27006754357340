import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { PolicyBaseComponent, PolicyBroadcasterService, LoginService, UtilityProviderService } from '@prcins/utils';
import { menus, allowed_menus, CURRENT_POLICY_STATUS, CURRENT_POLICY_LOB, TOKEN, USER_LOGIN, ADMIN, USER_TYPE, USER_ID, GUEST_USER_ID, BASE_URL } from '@prcins/constants';
import { BehaviorSubject } from 'rxjs';
import { RightRailService } from '@prcins/utils';
import { CURRENT_POLICY, CURRENT_POLICY_PRODUCT_CODE } from '@prcins/constants';
import { PolicyProviderService } from '../services/policy-provider.service';
import { map } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
var HeaderComponent = /** @class */ (function (_super) {
    tslib_1.__extends(HeaderComponent, _super);
    function HeaderComponent(router, route, apollo, pb, loginService, utilityService, rightRailService, cookieService, policyProvider) {
        var _this = _super.call(this, pb) || this;
        _this.router = router;
        _this.route = route;
        _this.apollo = apollo;
        _this.pb = pb;
        _this.loginService = loginService;
        _this.utilityService = utilityService;
        _this.rightRailService = rightRailService;
        _this.cookieService = cookieService;
        _this.policyProvider = policyProvider;
        _this.selectedHeader = 'home';
        _this.menus$ = new BehaviorSubject([]);
        _this.menus = _this.menus$.asObservable();
        _this.activeMenus = {
            payments: false,
            policy: false,
            claims: false,
            documents: false,
            account: false
        };
        return _this;
    }
    HeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.checkRouter(location.href);
        this.router.events.subscribe(function (routerEvent) {
            if (routerEvent instanceof NavigationEnd) {
                _this.checkRouter(routerEvent.url);
            }
        });
        this.utilityService.unauthorizedObservable.subscribe(function (isUnauthorized) {
            if (isUnauthorized) {
                _this.onLogout();
            }
        });
    };
    HeaderComponent.prototype.loadUiData = function (policyNumber) {
        var _this = this;
        var lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
        var policyStatus = sessionStorage.getItem(CURRENT_POLICY_STATUS);
        var productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);
        if (lob) {
            var allowedMenus_2 = null;
            if (policyStatus === 'Incomplete') {
                allowedMenus_2 = allowed_menus[lob].filter(function (m) { return m && m.startsWith('account'); }).map(function (m) { return menus[m]; });
                allowedMenus_2[0].children[1].disable = true;
            }
            else {
                allowedMenus_2 = allowed_menus[lob].map(function (m) { return menus[m]; });
                allowedMenus_2.filter(function (m) { return m && m.id.startsWith('account'); }).forEach(function (m) {
                    m.children[1].disable = false;
                });
                if (productCode === 'PCAT' || productCode === 'ALN_UMB') {
                    allowedMenus_2 = allowed_menus['UMB'].map(function (m) { return menus[m]; });
                }
                // restore  conditions
                if (lob === 'PA') {
                    allowedMenus_2[0].children[4].waitConditionally = true;
                    allowedMenus_2[0].children[4].disable = true;
                }
                if (lob === 'HO' || lob === 'PA') {
                    allowedMenus_2[3].children[0].waitConditionally = true;
                    allowedMenus_2[3].children[0].disable = true;
                }
                if (policyStatus === 'Canceled' || policyStatus === 'Expired') {
                    allowedMenus_2[0].children[1].waitConditionally = false;
                    allowedMenus_2[0].children[1].disable = true;
                }
                else {
                    allowedMenus_2[0].children[1].waitConditionally = false;
                    allowedMenus_2[0].children[1].disable = false;
                }
            }
            if (sessionStorage.getItem("lob") === 'PA') {
                var showRRSubMenu_1 = false;
                this.utilityService.inquiryLoaded$.subscribe(function (isInquiryLoaded) {
                    if (isInquiryLoaded) {
                        _this.policyProvider.getRRDriversDetails(policyNumber).subscribe(function (val) {
                            console.log(val);
                            if (val["errorCode"] == "") {
                                console.info(val["data"]);
                                var data = val["data"];
                                //let product = data["product"]
                                var channel = sessionStorage.getItem("channel");
                                console.info(data["drivers"]);
                                var drivers$ = data["drivers"];
                                if (drivers$ != null) {
                                    for (var _i = 0, drivers$_1 = drivers$; _i < drivers$_1.length; _i++) {
                                        var driver = drivers$_1[_i];
                                        if ((channel == 'IA' &&
                                            driver.activationCode != null &&
                                            driver.registerDate == null) ||
                                            (channel == 'IA' &&
                                                driver.activationCode != null &&
                                                driver.registerDate != null)) {
                                            showRRSubMenu_1 = true;
                                            allowedMenus_2 = allowed_menus[lob].map(function (m) { return menus[m]; });
                                            allowedMenus_2.filter(function (m) { return m && m.id.startsWith('policy'); }).forEach(function (m) {
                                                if (m.children[5] != null) {
                                                    m.children[5].disable = false;
                                                }
                                            });
                                            break;
                                        }
                                    }
                                }
                            }
                        });
                    }
                });
                allowedMenus_2 = allowed_menus[lob].map(function (m) { return menus[m]; });
                allowedMenus_2.filter(function (m) { return m && m.id.startsWith('policy'); }).forEach(function (m) {
                    if (m.children[5] != null) {
                        m.children[5].disable = true;
                    }
                });
            }
            for (var _i = 0, allowedMenus_1 = allowedMenus_2; _i < allowedMenus_1.length; _i++) {
                var menu = allowedMenus_1[_i];
                var done = false;
                if (done) {
                    break;
                }
                if (menu.children && menu.children.length) {
                    for (var _a = 0, _b = menu.children; _a < _b.length; _a++) {
                        var child = _b[_a];
                        if (child.label === 'Manage eDocument Preferences') {
                            child.disable = this.policeNoEdoc();
                            done = true;
                            break;
                        }
                    }
                }
            }
            if (sessionStorage.getItem(GUEST_USER_ID) !== null) {
                allowedMenus_2 = allowedMenus_2.filter(function (m) { return m && m.label !== 'Account'; }).map(function (m) { return m; });
            }
            this.menus$.next(allowedMenus_2);
            this.updateMenuDisables(allowedMenus_2, lob);
        }
    };
    HeaderComponent.prototype.updateMenuDisables = function (allowedMenus, lob) {
        var _this = this;
        var currentPolicy = sessionStorage.getItem(CURRENT_POLICY);
        var currentPolicyStatus = sessionStorage.getItem(CURRENT_POLICY_STATUS);
        var productCode = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);
        if (lob === 'PA' || this.isCommercialPolicy()) {
            this.rightRailService.getQuickLinks(currentPolicy)
                .pipe(map(function (rightRails) {
                if (currentPolicyStatus !== 'Incomplete') {
                    var isMrbActive = (rightRails && rightRails.mrbActive === false) ? false : true;
                    if (_this.isCommercialPolicy()) {
                        //Change Pay Plan
                        if (isMrbActive || rightRails.showPayPlanChange) {
                            allowedMenus[0].children[3].waitConditionally = false;
                            allowedMenus[0].children[3].disable = false;
                            _this.menus$.next(allowedMenus);
                        }
                        else {
                            allowedMenus[0].children[3].waitConditionally = false;
                            _this.menus$.next(allowedMenus);
                        }
                        //ID Cards
                        if (rightRails.showAutoProof) {
                            allowedMenus[2].children[0].waitConditionally = false;
                            allowedMenus[2].children[0].disable = false;
                            _this.menus$.next(allowedMenus);
                        }
                        else {
                            allowedMenus[2].children[0].waitConditionally = false;
                            _this.menus$.next(allowedMenus);
                        }
                    }
                    else {
                        if (isMrbActive || rightRails.showPayPlanChange) {
                            allowedMenus[0].children[4].waitConditionally = false;
                            allowedMenus[0].children[4].disable = false;
                            _this.menus$.next(allowedMenus);
                        }
                        else {
                            allowedMenus[0].children[4].waitConditionally = false;
                            _this.menus$.next(allowedMenus);
                        }
                    }
                }
            })).subscribe();
        }
        if (lob === 'HO') { // Id cards proof of insurance label disable logic
            if (currentPolicyStatus !== 'Incomplete' && (currentPolicyStatus.indexOf('Active') !== -1 || currentPolicyStatus.indexOf('Cancellation') !== -1)) {
                allowedMenus[3].children[0].waitConditionally = false;
                allowedMenus[3].children[0].disable = false;
                this.menus$.next(allowedMenus);
            }
            else {
                allowedMenus[3].children[0].waitConditionally = false;
                this.menus$.next(allowedMenus);
            }
        }
        else {
            if (currentPolicyStatus !== 'Incomplete') {
                var docsarrayIndex = 3;
                if (lob === 'CA') {
                    docsarrayIndex = 2;
                }
                if (!this.isCommercialPolicy()) {
                    allowedMenus[docsarrayIndex].children[0].waitConditionally = false;
                    allowedMenus[docsarrayIndex].children[0].disable = false;
                }
                this.menus$.next(allowedMenus);
            }
        }
        // for manage eDocument Preferences write a similiar logic to disable it
        this.rightRailService.getQuickLinks(currentPolicy)
            .pipe(map(function (rightRails) {
            if (currentPolicyStatus !== 'Incomplete') {
                var isMrbActive = (rightRails && rightRails.mrbActive === false) ? false : true;
                if (productCode !== 'PAIP') {
                    if (isMrbActive || rightRails.showPayPlanChange) {
                        if (allowedMenus[0].children[4]) {
                            allowedMenus[0].children[4].waitConditionally = false;
                            allowedMenus[0].children[4].disable = false;
                        }
                        _this.menus$.next(allowedMenus);
                    }
                    else {
                        if (allowedMenus[0].children[4]) {
                            allowedMenus[0].children[4].waitConditionally = false;
                        }
                        _this.menus$.next(allowedMenus);
                    }
                }
                else {
                    allowedMenus[0].children[4].waitConditionally = true;
                    allowedMenus[0].children[4].disable = true;
                    allowedMenus[3].children[2].disable = true;
                }
            }
        })).subscribe();
    };
    HeaderComponent.prototype.checkRouter = function (url) {
        if (url.includes('home/billing') || url.includes('home/payment')) {
            this.selectedHeader = 'payments';
        }
        else if (url.includes('home/policy') ||
            url.includes('home/documents/policy-declaration') ||
            url.includes('home/address')) {
            this.selectedHeader = 'policy';
        }
        else if (url.includes('home/claims')) {
            this.selectedHeader = 'claims';
        }
        else if (url.includes('home/documents')) {
            this.selectedHeader = 'documents';
        }
        else if (url.includes('home/account')) {
            this.selectedHeader = 'account';
        }
        else {
            this.selectedHeader = 'home';
        }
    };
    HeaderComponent.prototype.onSoftLogout = function () {
        var preservedStorage = {
            token: sessionStorage.getItem(TOKEN),
            userLogin: sessionStorage.getItem(USER_LOGIN),
            admin: sessionStorage.getItem(ADMIN),
            userType: sessionStorage.getItem(USER_TYPE),
            userId: sessionStorage.getItem(USER_ID),
            baseURL: sessionStorage.getItem(BASE_URL)
        };
        this.utilityService.clearAppStorage();
        Object.keys(preservedStorage).forEach(function (key) {
            sessionStorage.setItem(key, preservedStorage[key]);
        });
        this.router.navigate(['/eservice/admin/activity'], { relativeTo: this.route });
    };
    HeaderComponent.prototype.onLogout = function () {
        this.utilityService.clearAppStorage();
        this.utilityService.clearCookies();
        this.loginService.userLoggedIn$.next(false);
        this.router.navigate(['../login'], { relativeTo: this.route });
    };
    HeaderComponent.prototype.policeNoEdoc = function () {
        if (sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE) === 'PCAT') {
            return true;
        }
        var policyNumber = sessionStorage.getItem(CURRENT_POLICY);
        var business = ['PAC', 'PRC', 'PGC', 'CSC'];
        for (var _i = 0, business_1 = business; _i < business_1.length; _i++) {
            var item = business_1[_i];
            if (policyNumber.indexOf(item) !== -1) {
                return true;
            }
        }
        return false;
    };
    HeaderComponent.prototype.isCommercialPolicy = function () {
        var lob = sessionStorage.getItem(CURRENT_POLICY_LOB);
        var productCd = sessionStorage.getItem(CURRENT_POLICY_PRODUCT_CODE);
        return lob === "CA" && !(productCd === "PCAT" || productCd === "ALN_UMB");
    };
    return HeaderComponent;
}(PolicyBaseComponent));
export { HeaderComponent };
