import * as tslib_1 from "tslib";
import { GuardNotificationBase } from '../guard-notification-base/guard-notification-base';
var IdCardsNotificationComponent = /** @class */ (function (_super) {
    tslib_1.__extends(IdCardsNotificationComponent, _super);
    function IdCardsNotificationComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return IdCardsNotificationComponent;
}(GuardNotificationBase));
export { IdCardsNotificationComponent };
