import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DiscardComponent } from '@prcins/utils';
import { RegistrationService, UtilityProviderService, FormValidatorService } from '@prcins/utils';
import { ClaimsBasicService } from "libs/claims/src/lib/claims-basic.service";
import { USER_LOGIN, TOKEN } from '@prcins/constants';
var ForgotPasswordComponent = /** @class */ (function (_super) {
    tslib_1.__extends(ForgotPasswordComponent, _super);
    function ForgotPasswordComponent(router, route, formValidator, utilityProvider, claimsBasicService, registrationProvider) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.route = route;
        _this.formValidator = formValidator;
        _this.utilityProvider = utilityProvider;
        _this.claimsBasicService = claimsBasicService;
        _this.registrationProvider = registrationProvider;
        _this.showSpinner = false;
        _this.resetPasswordForm = _this.utilityProvider.getResetPasswordForm();
        _this.registrationError = false;
        _this.accountLockError = false;
        return _this;
    }
    ForgotPasswordComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.verificationId = sessionStorage.getItem('verificationId');
        if (this.verificationId) {
            this.updateSafeToNavigate(false);
        }
        if (history.state.email) {
            this.resetPasswordForm.controls['userName'].setValue(history.state.email);
        }
        else {
            this.registrationProvider.getUserDetails(this.verificationId).subscribe(function (data) {
                if (data) {
                    _this.resetPasswordForm.controls['userName'].setValue(data.emailAddress);
                }
            }, function (error) {
                _this.registrationProvider.invalidateEndorsementSession();
                _this.router.navigate(['../login'], {
                    relativeTo: _this.route,
                });
            });
        }
    };
    ForgotPasswordComponent.prototype.onContinue = function () {
        var _this = this;
        if (this.resetPasswordForm.valid) {
            this.registrationError = false;
            this.accountLockError = false;
            this.showSpinner = true;
            var formValue = this.resetPasswordForm.value;
            formValue.verificationId = this.verificationId;
            delete formValue.confirmPassword;
            // const payload = {
            //   "policyNumber": "",
            //   "agreementType": "GENERALTC",
            //   "agreementType2": "ELECTRONICCONSENT",
            //   "source": "ESERVICE",
            //   "email": sessionStorage.getItem(USER_ID)||''
            // }
            // this.claimsBasicService.eDiscloserAudit(payload).subscribe();
            this.registrationProvider
                .changePassword(formValue)
                .subscribe(function (resp) {
                if (resp.messageCode === 'PASSWORD_UPDATED') {
                    sessionStorage.setItem(USER_LOGIN, 'Y');
                    sessionStorage.setItem(TOKEN, resp.token);
                    sessionStorage.setItem('randomNumber', Math.random().toString());
                    _this.registrationProvider.invalidateEndorsementSession();
                    _this.updateSafeToNavigate(true);
                    _this.showSpinner = false;
                    var routePath = ['eservice/home'];
                    _this.router.navigate(routePath);
                }
                else if (resp.messageCode === 'UNAVAILABLE_FOR_15MIN') {
                    _this.accountLockError = true;
                }
                else {
                    _this.registrationError = true;
                    _this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
                }
                _this.showSpinner = false;
            }, function (error) {
                _this.showSpinner = false;
                _this.registrationError = true;
                _this.errorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
            });
        }
        else {
            this.formValidator.validateAllFormFields(this.resetPasswordForm);
        }
    };
    ForgotPasswordComponent.prototype.cancelTransaction = function () {
        this.registrationProvider.invalidateEndorsementSession();
        // this.chooseDeactivate(true);
        this.updateSafeToNavigate(true);
        this.router.navigate(['../login'], {
            relativeTo: this.route,
        });
    };
    return ForgotPasswordComponent;
}(DiscardComponent));
export { ForgotPasswordComponent };
