import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
var MessageBannerComponent = /** @class */ (function () {
    function MessageBannerComponent(apollo, sanitizer) {
        this.apollo = apollo;
        this.sanitizer = sanitizer;
    }
    MessageBannerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getBannerMessage("").subscribe(function (data) {
            if (data && data.uiMessages) {
                if (data.uiMessages.length > 0) {
                    _this.text = data.uiMessages;
                }
            }
        });
    };
    MessageBannerComponent.prototype.getBannerMessage = function (username) {
        return this.apollo
            .watchQuery({
            query: BannerMessageQuery,
            variables: {
                username: username
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    return MessageBannerComponent;
}());
export { MessageBannerComponent };
export var BannerMessageQuery = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  query Uimessages($username: String!) {\n    uiMessages(username: $username) {\n      richText\n    }\n  }\n"], ["\n  query Uimessages($username: String!) {\n    uiMessages(username: $username) {\n      richText\n    }\n  }\n"])));
var templateObject_1;
