import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { PolicyEndorseDriverQuery } from "@prcins/utils";
import { Apollo } from 'apollo-angular';
import { environment } from '@prcins/environments';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var OpenamendmentService = /** @class */ (function () {
    function OpenamendmentService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
        this.apiUrl = environment.BASE_URL + "/endorsement/openForAmend";
        this.deleteDriverUrl = environment.BASE_URL + "/endorsement/driver/addmore";
        this.submitDriverUrl = environment.BASE_URL + "/endorsement/driver/submit";
        this.cancelEndorseUrl = environment.BASE_URL + "/endorsement/abandon?aiGuid=";
        this.driverAdapterUrl = environment.BASE_URL + "/v2/core/";
        this.openEndorsementUrl = environment.BASE_URL + "/v2/";
    }
    // openForAmendment(request: IOpenAmendmentRequest): Observable<any> {
    //   return this.http.post(this.apiUrl, request);
    // }
    OpenamendmentService.prototype.recordEndorsmentBlocked = function (type, guid) {
        var policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.put(this.openEndorsementUrl + 'core/' + policyNum + '/endorsements/recordEndorsmentBlocked?type=' + type + (guid ? '&guid=' + guid : ''), {});
    };
    OpenamendmentService.prototype.createEndorsement = function (type) {
        var policyNum = sessionStorage.getItem('currentPolicy');
        return this.http.post(this.openEndorsementUrl + 'core/' + policyNum + '/endorsements?type=' + type, {});
    };
    OpenamendmentService.prototype.deleteDriver = function (request) {
        return this.http.post(this.deleteDriverUrl, request);
    };
    OpenamendmentService.prototype.submitDriver = function (request) {
        return this.http.post(this.submitDriverUrl, request);
    };
    OpenamendmentService.prototype.getDriverAdapterDetails = function (policyNum, guid) {
        return this.http.get(this.driverAdapterUrl + policyNum + '/endorsements/' + guid + '/driveradapter');
    };
    OpenamendmentService.prototype.setEndorsementPolicyInput = function (actionType, policy) {
        sessionStorage.setItem(actionType, JSON.stringify(policy));
    };
    OpenamendmentService.prototype.getEndorseDriverInfo = function () {
        return JSON.parse(sessionStorage.endorseDriver);
    };
    OpenamendmentService.prototype.updateEndorsementPolicyInput = function (driverId, driverSeqNum) {
        var obj = JSON.parse(sessionStorage.endorseDriver);
        obj.driverId = driverId;
        obj.driverSeqNum = driverSeqNum;
        sessionStorage.setItem("endorseDriver", JSON.stringify(obj));
    };
    OpenamendmentService.prototype.setEndorsementAllDriversInfo = function (drivers) {
        sessionStorage.setItem("allDriversInfo", JSON.stringify(drivers));
    };
    OpenamendmentService.prototype.getPolicyDriverInformation = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyEndorseDriverQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyDetails;
        }));
    };
    OpenamendmentService.prototype.endorsementConfig = function (policyNumber) {
        return this.apollo.watchQuery({
            query: EndorsementConfigQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber: policyNumber
            }
        }).valueChanges;
    };
    OpenamendmentService.prototype.getEndorsementConfig = function (policyNumber) {
        return this.endorsementConfig(policyNumber).pipe(map(function (result) { return result.data.getEndorsementResourceConfig; }));
    };
    //Deprecated in favor of new core APIs
    OpenamendmentService.prototype.cancelEndorsement_deprecated = function () {
        var url = this.cancelEndorseUrl + sessionStorage.getItem("aiguid") + "&source=CI";
        return this.http.delete(url);
    };
    OpenamendmentService.prototype.cancelEndorsement = function () {
        var policyNum = sessionStorage.getItem('currentPolicy') ? sessionStorage.getItem('currentPolicy') : sessionStorage.getItem('splPolicyNumber');
        var guid = sessionStorage.getItem('guid') ? sessionStorage.getItem('guid') : sessionStorage.getItem('splGuid');
        var url = this.driverAdapterUrl + policyNum + '/endorsements/' + guid;
        return this.http.delete(url);
    };
    OpenamendmentService.prototype.driverQuery = function (policyNumber, term, guid, aiGuid, filter) {
        return this.apollo.watchQuery({
            query: EndorsmentPolicyDetailsQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber: policyNumber,
                term: term,
                guid: guid,
                aiGuid: aiGuid,
                filter: filter
            }
        }).valueChanges;
    };
    OpenamendmentService.prototype.getEndorseDriverDetails = function (policyNumber, term, guid, aiGuid, filter) {
        return this.driverQuery(policyNumber, term, guid, aiGuid, filter).pipe(map(function (result) { return result.data.endorsementPolicyDetails; }));
    };
    OpenamendmentService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function OpenamendmentService_Factory() { return new OpenamendmentService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: OpenamendmentService, providedIn: "root" });
    return OpenamendmentService;
}());
export { OpenamendmentService };
export var EndorsementConfigQuery = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  query GetEndorsementResourceConfig($policyNumber: String) {\n    getEndorsementResourceConfig(policyNumber: $policyNumber) {\n      addDriver\n      updateDriver\n      deleteDriver\n      endorseEligiblePolicyInd\n   }\n  }\n"], ["\n  query GetEndorsementResourceConfig($policyNumber: String) {\n    getEndorsementResourceConfig(policyNumber: $policyNumber) {\n      addDriver\n      updateDriver\n      deleteDriver\n      endorseEligiblePolicyInd\n   }\n  }\n"])));
export var EndorsmentPolicyDetailsQuery = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  query EndorsementPolicyDetails($policyNumber: String, $term:String, $guid: String, $aiGuid: String,,$filter: String) {\n    endorsementPolicyDetails(policyNumber: $policyNumber, term: $term, guid: $guid, aiGuid: $aiGuid) {\n      guid\n      messageCode\n      driverDetails(filter:$filter){\n        firstName\n        middleName\n        lastName\n       \n }\n vehiclesAssignedToDriver(filter:$filter){\n  vehicleId\n  year\n  make\n  model\n  vin\n}\notherDriversOnPolicy(filter:$filter){\n  firstName\n  lastName\n  driverId\n}\n\n    }\n  }\n"], ["\n  query EndorsementPolicyDetails($policyNumber: String, $term:String, $guid: String, $aiGuid: String,,$filter: String) {\n    endorsementPolicyDetails(policyNumber: $policyNumber, term: $term, guid: $guid, aiGuid: $aiGuid) {\n      guid\n      messageCode\n      driverDetails(filter:$filter){\n        firstName\n        middleName\n        lastName\n       \n }\n vehiclesAssignedToDriver(filter:$filter){\n  vehicleId\n  year\n  make\n  model\n  vin\n}\notherDriversOnPolicy(filter:$filter){\n  firstName\n  lastName\n  driverId\n}\n\n    }\n  }\n"])));
var templateObject_1, templateObject_2;
