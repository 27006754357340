import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PaymentProviderService } from '../services/payment-provider.service';
import { ADMIN_QPAY_TOKEN, CURRENT_POLICY, EMPLOYEE_QPAY_PAYMENT_SOURCE, PAYMENT_AMOUNT, PAYMENT_ID, PAYMENT_SOURCE, QPAY_PAYMENT_DETAILS, QPAY_REGISTRATION_EMAIL, SAVED_WALLET, SAVE_WALLET_ERROR, USER_ID } from '@prcins/constants';
import { DiscardComponent } from '@prcins/utils';
import { tap, switchMapTo } from 'rxjs/operators';
import { BRAND, Brand } from '@prcins/constants';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { Bank, Card } from '../model/wallet';
var PaymentReviewComponent = /** @class */ (function (_super) {
    tslib_1.__extends(PaymentReviewComponent, _super);
    function PaymentReviewComponent(router, route, paymentProvider, managePaymentsProviderService) {
        var _this = _super.call(this) || this;
        _this.router = router;
        _this.route = route;
        _this.paymentProvider = paymentProvider;
        _this.managePaymentsProviderService = managePaymentsProviderService;
        _this.isReviewLoaded = false;
        _this.isConfirmInProgress = false;
        _this.isReviewError = false;
        _this.reviewErrorMessage = '';
        _this.brand = localStorage.getItem(BRAND);
        _this.companyName = Brand[_this.brand].companyName;
        _this.validationCounter = 0;
        return _this;
    }
    PaymentReviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.deactivateGuardSelection$.subscribe(function (selection) {
            if (selection) {
                _this.paymentProvider.resetACHFailureCount();
            }
        });
        this.validationCounter = 0;
        this.policyNumber = sessionStorage.getItem(CURRENT_POLICY);
        this.params = this.route.snapshot.queryParams;
        var paymentD = this.paymentProvider.getPolicyPaymentDetails();
        this.paymentDetails = paymentD.paymentDetails;
        if (!this.paymentDetails) {
            this.paymentProvider
                .getPaymentDetailsFromSession(this.policyNumber)
                .pipe(tap(function (data) {
                _this.paymentDetails = data;
                _this.isSavedPaymentMethod = !!(_this.paymentDetails.savedPaymentMethod);
            }), switchMapTo(this.paymentProvider.getPolicyReviewDetails(this.policyNumber)))
                .subscribe(function (_a) {
                var policyDetails = _a.policyDetails, policySearchInfo = _a.policySearchInfo;
                _this.policyDetails = policyDetails.product;
                _this.addressDetails = policyDetails.primaryAddress;
                _this.userDetails = policySearchInfo;
                _this.isReviewLoaded = true;
            });
        }
        else {
            this.paymentProvider
                .getPolicyReviewDetails(this.policyNumber)
                .subscribe(function (_a) {
                var policyDetails = _a.policyDetails, policySearchInfo = _a.policySearchInfo;
                _this.policyDetails = policyDetails.product;
                _this.addressDetails = policyDetails.primaryAddress;
                _this.userDetails = policySearchInfo;
                _this.isReviewLoaded = true;
            });
        }
        this.updateSafeToNavigate(false);
    };
    PaymentReviewComponent.prototype.onEditInfo = function () {
        this.updateSafeToNavigate(true);
        this.router.navigate(['../entry'], { relativeTo: this.route, queryParams: this.params });
    };
    PaymentReviewComponent.prototype.onAuthorizePayment = function (paymentMethod) {
        this.isConfirmInProgress = true;
        this.updateSafeToNavigate(true);
        if (paymentMethod === 'cc') {
            this.onCardPayment();
        }
        else if (paymentMethod === 'eft') {
            this.onEftPayment();
        }
    };
    PaymentReviewComponent.prototype.onCardPayment = function () {
        var _this = this;
        var cardPayload = this.isSavedPaymentMethod ? this.buildSavedCardPaymentRequest() : this.buildCardPaymentRequest();
        this.paymentProvider
            .makePayment('card', cardPayload)
            .subscribe(function (data) { return _this.handlePaymentResponse(data); }, function (error) { return _this.handleServiceError(); });
    };
    PaymentReviewComponent.prototype.onEftPayment = function () {
        var _this = this;
        this.isSavedPaymentMethod = !!(this.paymentDetails.savedPaymentMethod);
        var accountPayload = this.isSavedPaymentMethod ? this.buildSavedBankPaymentRequest() : this.buildBankPaymentRequest();
        this.paymentProvider
            .makePayment('cheque', accountPayload)
            .subscribe(function (data) { return _this.handlePaymentResponse(data); }, function (error) { return _this.handleServiceError(); });
    };
    PaymentReviewComponent.prototype.getPolicyNameDetails = function () {
        // const { businessName } = this.policyDetails;
        var _a = this.userDetails, firstName = _a.firstName, lastName = _a.lastName, businessName = _a.businessName;
        if (businessName) {
            if (businessName.length > 15) {
                firstName = businessName.substr(0, 15);
                lastName = businessName.substr(15, 50);
            }
            else {
                var si = businessName.indexOf(' ');
                if (si !== -1) {
                    firstName = businessName.substr(0, si);
                    lastName = businessName.substr(si, 50);
                }
            }
        }
        return { firstName: firstName, lastName: lastName, middleName: this.userDetails.middleName };
    };
    PaymentReviewComponent.prototype.basePaymentRequest = function () {
        var _a = this.policyDetails, productCode = _a.productCode, lobCode = _a.lobCode, effectiveDate = _a.effectiveDate, companyCode = _a.companyCode, channel = _a.channel, programIdCode = _a.programIdCode;
        var _b = this.addressDetails, address = _b.addressLine1, state = _b.state, city = _b.city, zip = _b.zipCode;
        var _c = this.paymentDetails, paymentAmount = _c.paymentAmount, paymentOptionSelected = _c.paymentOptionSelected;
        // Save amount in session for confirm page message on refresh
        sessionStorage.setItem(PAYMENT_AMOUNT, paymentAmount);
        var _d = this.getPolicyNameDetails(), firstName = _d.firstName, lastName = _d.lastName, middleName = _d.middleName;
        var paymentMethod = 'electronic_withdrawal';
        var cardType;
        if (paymentOptionSelected === 'cc') {
            paymentMethod = 'credit_debit';
            cardType = 'credit';
        }
        var isAdmin = sessionStorage.getItem(ADMIN_QPAY_TOKEN);
        return {
            paymentAmount: paymentAmount,
            policyFirstName: firstName,
            policyLastName: lastName,
            policyMiddleName: middleName,
            policyNumber: this.policyNumber,
            policyStreet1: address,
            policyState: state,
            policyCity: city,
            policyZip: zip.substring(0, 5),
            policy_Effective_Date: effectiveDate,
            uwCompany: companyCode,
            lob: productCode === 'PCAT' || productCode === 'ALN_UMB' ? 'PCAT' : lobCode,
            productCd: productCode === 'ALN_MC' ? 'ALN_PA' : productCode,
            payment_method: paymentMethod,
            card_type: cardType,
            app: (self != top) ? 'mobileapp' : (isAdmin && isAdmin.toLowerCase() === 'true') ? EMPLOYEE_QPAY_PAYMENT_SOURCE : sessionStorage.getItem(PAYMENT_SOURCE) || 'eservice',
            channel: channel,
            programIdCode: programIdCode
        };
    };
    PaymentReviewComponent.prototype.buildCardPaymentRequest = function () {
        var _a = this.paymentDetails.cardForm, cardNumber = _a.cardNumber, securityCode = _a.securityCode, expirationDate = _a.expirationDate;
        return this.returnCardRequest(securityCode, expirationDate, cardNumber);
    };
    PaymentReviewComponent.prototype.returnCardRequest = function (securityCode, expirationDate, cardNumber) {
        var basePayload = this.basePaymentRequest();
        var _a = this.paymentDetails.cardForm, cardHolderName = _a.cardHolderName, cardHolderEmailId = _a.cardHolderEmailId;
        var _b = this.addressDetails, address = _b.addressLine1, state = _b.state, city = _b.city, zip = _b.zipCode;
        return tslib_1.__assign({}, basePayload, { email: cardHolderEmailId, cardCvv: securityCode, cardExpiration: expirationDate, cardNumber: cardNumber, cardOwnerName: cardHolderName, cardStreet1: address, cardState: state, cardCity: city, cardZip: zip.substring(0, 5) });
    };
    PaymentReviewComponent.prototype.buildSavedCardPaymentRequest = function () {
        var _a = this.paymentDetails.savedPaymentMethod, cardNumber = _a.debitAccount, expirationDate = _a.expirationDate;
        sessionStorage.removeItem(SAVED_WALLET);
        return this.returnCardRequest(this.paymentDetails.savedCardSecurityCode, expirationDate, 'X' + cardNumber);
    };
    PaymentReviewComponent.prototype.buildBankPaymentRequest = function () {
        var _a = this.paymentDetails.eftForm, accountType = _a.accountType, accountNumber = _a.accountNumber, routingNumber = _a.routingNumber;
        return this.returnBankRequest(accountType, accountNumber, routingNumber);
    };
    PaymentReviewComponent.prototype.returnBankRequest = function (accountType, accountNumber, routingNumber) {
        var basePayload = this.basePaymentRequest();
        if (this.paymentDetails.confirmationEmail) {
            this.paymentDetails.eftForm.accountEmailId = this.paymentDetails.confirmationEmail;
        }
        var _a = this.paymentDetails.eftForm, accountHolderName = _a.accountHolderName, accountEmailId = _a.accountEmailId;
        var _b = this.addressDetails, address = _b.addressLine1, state = _b.state, city = _b.city, zip = _b.zipCode;
        var bankAccountType = accountType === 'C' ? 'checking' : 'saving';
        var transactionType = accountType === 'C' ? 'D' : 'S';
        return tslib_1.__assign({}, basePayload, { email: accountEmailId, bankAccountType: bankAccountType, checkAccountNumber: accountNumber, checkRoutingNumber: routingNumber, checkOwnerName: accountHolderName, checkStreet1: address, checkState: state, checkCity: city, checkZip: zip.substring(0, 5), transactionType: transactionType });
    };
    PaymentReviewComponent.prototype.buildSavedBankPaymentRequest = function () {
        var _a = this.paymentDetails.savedPaymentMethod, accountType = _a.transType, accountNumber = _a.debitAccount, routingNumber = _a.debitRouting;
        sessionStorage.removeItem(SAVED_WALLET);
        accountType = accountType === "CHECKING" ? 'C' : 'S';
        return this.returnBankRequest(accountType, 'X' + accountNumber, routingNumber);
    };
    PaymentReviewComponent.prototype.handleServiceError = function () {
        this.isConfirmInProgress = false;
        this.isReviewError = true;
        this.reviewErrorMessage = 'Oops! We seem to be having a problem. Please try again later or contact us for help.';
    };
    PaymentReviewComponent.prototype.handlePaymentResponse = function (response) {
        var _this = this;
        var savedPaymentMethod$;
        if (!response.errorCode) {
            this.paymentProvider.resetACHFailureCount();
            sessionStorage.setItem(PAYMENT_ID, response.paymentId);
            if (this.paymentDetails.saveThisBank) {
                savedPaymentMethod$ = this.saveBankPaymentMethod();
            }
            else if (this.paymentDetails.saveThisCard) {
                savedPaymentMethod$ = this.saveCardPaymentMethod();
            }
            else {
                this.isConfirmInProgress = false;
                this.navigateToConfirm();
            }
            savedPaymentMethod$.subscribe(function (response) {
                if (response == null || response.status === 'success' && response.error === "") {
                    sessionStorage.removeItem(SAVE_WALLET_ERROR);
                }
                else {
                    sessionStorage.setItem(SAVE_WALLET_ERROR, "Error: " + response.error);
                }
                _this.isConfirmInProgress = false;
                _this.navigateToConfirm();
            }, function (response) {
                sessionStorage.setItem(SAVE_WALLET_ERROR, response.error.message);
                _this.navigateToConfirm();
            });
        }
        else {
            if (this.paymentDetails.paymentOptionSelected === 'cc') {
                this.handleCardError(response);
            }
            else {
                this.handleEFTError(response);
            }
        }
    };
    PaymentReviewComponent.prototype.handleCardError = function (response) {
        this.isConfirmInProgress = false;
        this.isReviewError = true;
        this.reviewErrorMessage = response.errorMessage;
    };
    PaymentReviewComponent.prototype.handleEFTError = function (response) {
        var isACHFailure = false;
        this.isConfirmInProgress = false;
        var error = { code: response.errorCode };
        switch (response.errorCode) {
            case "4533003":
                error['message'] = "The Routing Number and Account Number provided cannot be used. Please provide a different payment method to continue with your payment.";
                this.paymentProvider.incrementACHFailureCount();
                isACHFailure = true;
                break;
            case "4533005":
                error['message'] = "The Routing Number provided cannot be validated. Please correct the routing number to continue with the payment.";
                this.paymentProvider.incrementACHFailureCount();
                isACHFailure = true;
                break;
            case "4533006":
                error['message'] = "The Account Number provided cannot be validated. Please correct the account number to continue with the payment.";
                this.paymentProvider.incrementACHFailureCount();
                isACHFailure = true;
                break;
            default:
                this.isReviewError = true;
                this.reviewErrorMessage = response.errorMessage;
        }
        if (isACHFailure) {
            if (this.paymentProvider.getACHFailureCount() > 2) {
                this.isReviewError = true;
                this.reviewErrorMessage = "The Routing Number and Account Number provided cannot be used. Please provide a different payment method to continue with your payment.";
            }
            else {
                this.updateSafeToNavigate(true);
                this.router.navigate(['../entry'], { relativeTo: this.route, queryParams: this.params, state: { error: error } });
            }
        }
    };
    PaymentReviewComponent.prototype.saveBankPaymentMethod = function () {
        var eftForm = this.paymentDetails.eftForm;
        sessionStorage.setItem(SAVED_WALLET, this.getSavedBankLabel(eftForm));
        var newBank = new Bank();
        var accountNumber = eftForm.accountNumber;
        newBank.debitAccount = accountNumber;
        newBank.debitRouting = eftForm.routingNumber;
        newBank.creditAccount = this.policyNumber;
        newBank.accountEmail = sessionStorage.getItem(USER_ID);
        newBank.paymentType = "ACH";
        var accountType = eftForm.accountType;
        if (accountType === 'C') {
            newBank.transType = "CHECKING";
        }
        else if (accountType === 'S') {
            newBank.transType = "SAVINGS";
        }
        return this.savePaymentMethod(newBank);
    };
    PaymentReviewComponent.prototype.getSavedBankLabel = function (eftForm) {
        return (eftForm.accountType === 'C' ? "Checking" : "Savings") + " *****" + eftForm.accountNumber.substring(eftForm.accountNumber.length - 4);
    };
    PaymentReviewComponent.prototype.savePaymentMethod = function (wallet) {
        wallet.debitZip = "12345"; // TODO - remove after ACI Speedpay fix
        return this.managePaymentsProviderService.createWallet(wallet, sessionStorage.getItem(PAYMENT_SOURCE));
    };
    PaymentReviewComponent.prototype.saveCardPaymentMethod = function () {
        var cardForm = this.paymentDetails.cardForm;
        sessionStorage.setItem(SAVED_WALLET, this.getSavedCardLabel(cardForm));
        // this.showSpinner = true;
        var newCard = new Card();
        newCard.debitAccount = cardForm.cardNumber;
        newCard.cvv = cardForm.securityCode;
        newCard.expirationMonth = cardForm.expirationMonth;
        newCard.expirationYear = cardForm.expirationYear;
        newCard.creditAccount = this.policyNumber;
        newCard.accountEmail = sessionStorage.getItem(USER_ID);
        newCard.paymentType = "CC"; // TODO - ATM Card?
        newCard.transType = "CARD";
        return this.savePaymentMethod(newCard);
    };
    PaymentReviewComponent.prototype.getSavedCardLabel = function (cardForm) {
        return cardForm.cardType + " *****" + cardForm.cardNumber.substring(cardForm.cardNumber.length - 4);
    };
    PaymentReviewComponent.prototype.navigateToConfirm = function () {
        var _this = this;
        this.paymentProvider
            .getPaymentDetailsFromSession(this.policyNumber)
            .subscribe(function (policyDetails) {
            if (policyDetails) {
                // put payment email for intermediate registeration
                var emailAddress = void 0;
                if (policyDetails && policyDetails.paymentOptionSelected === "cc") {
                    var confirmationEmail = policyDetails.confirmationEmail, cardHolderEmailId = policyDetails.cardForm.cardHolderEmailId;
                    emailAddress = confirmationEmail ? confirmationEmail : cardHolderEmailId;
                }
                else if (policyDetails && policyDetails.paymentOptionSelected === "eft") {
                    var confirmationEmail = policyDetails.confirmationEmail, accountEmailId = policyDetails.eftForm.accountEmailId;
                    emailAddress = confirmationEmail ? confirmationEmail : accountEmailId;
                }
                if (emailAddress) {
                    sessionStorage.setItem(QPAY_REGISTRATION_EMAIL, emailAddress);
                }
                // clear the session before navigating to confirmation
                sessionStorage.removeItem(QPAY_PAYMENT_DETAILS);
                _this.router.navigate(['../confirm'], {
                    relativeTo: _this.route
                });
            }
        });
    };
    PaymentReviewComponent.prototype.cancelPayment = function () {
        sessionStorage.removeItem(QPAY_PAYMENT_DETAILS);
    };
    PaymentReviewComponent.prototype.disableAutorization = function () {
        return this.paymentDetails.paymentOptionSelected === 'eft' && this.paymentProvider.getACHFailureCount() > 2;
    };
    return PaymentReviewComponent;
}(DiscardComponent));
export { PaymentReviewComponent };
