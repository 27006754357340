import { FormBuilder, Validators } from '@angular/forms';
import { routingValidatorFn, _ACHAccountValidatorFn, _ACHRoutingValidatorFn } from '../validators/payment-form.validators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/forms";
var BankFormProviderService = /** @class */ (function () {
    function BankFormProviderService(fb) {
        this.fb = fb;
    }
    BankFormProviderService.prototype.getForm = function () {
        return this.fb.group({
            accountType: ['', [Validators.required]],
            accountNumber: ['', [Validators.required, Validators.minLength(4), Validators.pattern(/^-?([0-9]\d*)?$/), _ACHAccountValidatorFn()]],
            maskedAccountNumber: ['', [Validators.required, Validators.minLength(4), _ACHAccountValidatorFn()]],
            reAccountNumber: ['', [Validators.required, Validators.minLength(4)]],
            maskedReAccountNumber: ['', [Validators.required, Validators.minLength(4)]],
            routingNumber: [
                '',
                [Validators.required, Validators.minLength(9), routingValidatorFn(), _ACHRoutingValidatorFn()]
            ],
            maskedRoutingNumber: ['', [Validators.required, _ACHRoutingValidatorFn()]]
        });
    };
    BankFormProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BankFormProviderService_Factory() { return new BankFormProviderService(i0.ɵɵinject(i1.FormBuilder)); }, token: BankFormProviderService, providedIn: "root" });
    return BankFormProviderService;
}());
export { BankFormProviderService };
