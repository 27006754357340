import { HttpClient } from "@angular/common/http";
import { environment } from "@prcins/environments";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MfaService = /** @class */ (function () {
    function MfaService(http) {
        this.http = http;
    }
    MfaService.prototype.getVerification = function (userId, type) {
        return this.http.post(environment.BASE_URL + '/account/' + userId + '/verifyuser', {
            type: type
        });
    };
    MfaService.prototype.saveChanges = function (verificationId, token, type, action) {
        return this.http.post(environment.BASE_URL + "/mfa/saveAction?type=" + type, {
            verificationId: verificationId,
            token: token,
            action: action
        });
    };
    MfaService.prototype.login = function (verificationId, token, type, action, captchaToken) {
        var headers = { 'headers': { 'userid': action.userId, 'tkn': captchaToken } };
        return this.http.post(environment.AUTH_URL + "/mfa/login?type=" + type, {
            verificationId: verificationId,
            token: token,
            action: action
        }, headers);
    };
    MfaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MfaService_Factory() { return new MfaService(i0.ɵɵinject(i1.HttpClient)); }, token: MfaService, providedIn: "root" });
    return MfaService;
}());
export { MfaService };
