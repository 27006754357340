import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { TogglerComponent } from '../toggler/toggler.component';
var RoundToggleSwitchComponent = /** @class */ (function (_super) {
    tslib_1.__extends(RoundToggleSwitchComponent, _super);
    function RoundToggleSwitchComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RoundToggleSwitchComponent;
}(TogglerComponent));
export { RoundToggleSwitchComponent };
/* -- Usage ---
  <prcins-round-toggle-switch
    [sliderValue]="value"
    [sliderKey]="key"
    (sliderChanged)="onChanged($event)">
  </prcins-round-toggle-switch>
--*/
