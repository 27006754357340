import { UtilityProviderService } from './utility-provider.service';
import * as i0 from "@angular/core";
import * as i1 from "./utility-provider.service";
var NavigationProviderService = /** @class */ (function () {
    function NavigationProviderService(utilityProvider) {
        this.utilityProvider = utilityProvider;
    }
    NavigationProviderService.prototype.gotoReportClaim = function () {
        this.utilityProvider.getClaimsToken().subscribe(function (data) {
            window.open(data, '_blank');
        });
    };
    NavigationProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NavigationProviderService_Factory() { return new NavigationProviderService(i0.ɵɵinject(i1.UtilityProviderService)); }, token: NavigationProviderService, providedIn: "root" });
    return NavigationProviderService;
}());
export { NavigationProviderService };
