import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { DiscardComponent, BankFormProviderService, FormValidatorService } from '@prcins/utils';
import { Router, ActivatedRoute } from '@angular/router';
import { ManagePaymentsProviderService } from '../services/manage-payments-provider.service';
import { USER_ID } from '@prcins/constants';
import { Bank } from '../model/wallet';
var EditBankComponent = /** @class */ (function (_super) {
    tslib_1.__extends(EditBankComponent, _super);
    function EditBankComponent(bankFormProvider, managePaymentsProviderService, formValidator, router, route) {
        var _this = _super.call(this) || this;
        _this.bankFormProvider = bankFormProvider;
        _this.managePaymentsProviderService = managePaymentsProviderService;
        _this.formValidator = formValidator;
        _this.router = router;
        _this.route = route;
        _this.showSpinner = false;
        _this.showError = false;
        _this.agentInfo = {};
        return _this;
    }
    EditBankComponent.prototype.ngOnInit = function () {
        this.policyNumber = this.route.snapshot.queryParamMap.get('policyNumber');
        // this.eftForm = this.bankFormProvider.getEditForm();
        this.eftForm = this.bankFormProvider.getForm();
        this.bank = this.managePaymentsProviderService.data;
        var accountType = this.bank.transType === 'CHECKING' ? 'C' : this.bank.transType === 'SAVINGS' ? 'S' : '';
        this.eftForm.get('accountType').setValue(accountType);
        this.eftForm.get('accountNumber').setValue(this.bank.debitAccount);
        this.eftForm.get('maskedAccountNumber').setValue("****" + this.bank.debitAccount);
        this.eftForm.get('reAccountNumber').setValue(this.bank.debitAccount);
        this.eftForm.get('maskedReAccountNumber').setValue("****" + this.bank.debitAccount);
        this.eftForm.get('routingNumber').setValue(this.bank.debitRouting);
        var debitRouting = this.bank.debitRouting + "";
        var maskedRouting = debitRouting.substring(debitRouting.length - 4);
        this.eftForm.get('maskedRoutingNumber').setValue("****" + maskedRouting);
        this.setAgentInfo();
    };
    EditBankComponent.prototype.doEditBank = function () {
        var _this = this;
        // form Validation
        this.formValidator.validateAllFormFields(this.eftForm);
        if (this.eftForm.valid && this.eftForm.get('accountNumber').value === this.eftForm.get('reAccountNumber').value) {
            this.showSpinner = true;
            var editedBank = new Bank();
            editedBank.debitAccount = this.eftForm.get('accountNumber').value;
            editedBank.debitRouting = this.eftForm.get('routingNumber').value;
            editedBank.creditAccount = this.policyNumber;
            editedBank.accountEmail = sessionStorage.getItem(USER_ID);
            editedBank.paymentType = "ACH";
            var accountType = this.eftForm.get('accountType').value;
            if (accountType === 'C') {
                editedBank.transType = "CHECKING";
            }
            else if (accountType === 'S') {
                editedBank.transType = "SAVINGS";
            }
            editedBank.paymentMethodId = this.bank.paymentMethodId;
            editedBank.accountNickName = this.bank.accountNickName; // TODO - remove after ACI Speedpay fix
            this.managePaymentsProviderService.editWallet(editedBank).subscribe(function (response) {
                if (response == null || response.status === 'success' && response.error === "") {
                    _this.navigateToManagePayments('true');
                }
                else {
                    alert(response.status + ", " + response.errorMessage);
                    // TODO - error
                }
            }, function (error) {
                _this.httpErrorResponse();
            });
        }
    };
    EditBankComponent.prototype.navigateToManagePayments = function (isSuccess) {
        sessionStorage.setItem('editWalletSuccess', isSuccess);
        this.router.navigate(['../manage'], {
            relativeTo: this.route
        });
    };
    EditBankComponent.prototype.cancelTransaction = function () {
        this.navigateToManagePayments('false');
    };
    EditBankComponent.prototype.httpErrorResponse = function () {
        this.showSpinner = false;
        this.showError = true;
    };
    EditBankComponent.prototype.setAgentInfo = function () {
        var agentDetails = sessionStorage.agentInfo;
        if (agentDetails) {
            this.agentInfo = JSON.parse(agentDetails);
        }
        this.generalPhone = sessionStorage.getItem('general-phone');
        this.channel = sessionStorage.channel;
    };
    return EditBankComponent;
}(DiscardComponent));
export { EditBankComponent };
