import * as tslib_1 from "tslib";
import gql from 'graphql-tag';
export var AddressListQuery = gql(templateObject_1 || (templateObject_1 = tslib_1.__makeTemplateObject(["\n  fragment AddressFragment on AddressDTO {\n    id\n    addressLine1\n    addressLine2\n    zipCode\n    city\n    state\n  }\n  fragment VehicleFragment on VehicleDTO {\n    id\n    unitYear\n    make\n    model\n    vin\n    bodyType\n  }\n  query AddressList($policyNumber: String) {\n    addressList(policyNumber: $policyNumber) {\n      primary\n      mailing\n      garaging {\n        address\n        vehicles\n      }\n      addresses {\n        ...AddressFragment\n      }\n      vehicles {\n        ...VehicleFragment\n      }\n    }\n  }\n"], ["\n  fragment AddressFragment on AddressDTO {\n    id\n    addressLine1\n    addressLine2\n    zipCode\n    city\n    state\n  }\n  fragment VehicleFragment on VehicleDTO {\n    id\n    unitYear\n    make\n    model\n    vin\n    bodyType\n  }\n  query AddressList($policyNumber: String) {\n    addressList(policyNumber: $policyNumber) {\n      primary\n      mailing\n      garaging {\n        address\n        vehicles\n      }\n      addresses {\n        ...AddressFragment\n      }\n      vehicles {\n        ...VehicleFragment\n      }\n    }\n  }\n"])));
// export const GaragingQuery = gql`
//   query GaragingQuery($policyNumber: String) {
//     addressList(policyNumber: $policyNumber) {
//       primary
//       garaging {
//         address
//         vehicles
//       }
//       addresses {
//         id
//         addressLine1
//         addressLine2
//         zipCode
//         city
//         state
//       }
//       vehicles {
//         id
//         unitYear
//         make
//         model
//         vin
//         vehicleCC
//         bodyType
//         insurableInterests{
//           nameBusiness
//           interestTypeCode
//         }
//       }
//     }
//   }
// `;
export var GaragingQuery = gql(templateObject_2 || (templateObject_2 = tslib_1.__makeTemplateObject(["\n  query GaragingQuery($policyNumber: String, $guid: String) {\n    policyDetailsV2(policyNumber: $policyNumber, guid: $guid) {\n      isEOAIEligible\n      product{\n        programRsCode\n      }\n      drivers {\n        identifier\n        lastName\n        firstName\n        middleName\n        suffix\n    }\n    vehicles {\n        id\n        unitYear\n        make\n        model\n        bodyType\n        vin,\n        vehicleCC\n        vehicleDrivers {\n            driverId\n        }\n        insurableInterests {\n            nameBusiness\n        }\n        vehicleAddress {\n            sameAsPrimary\n            addressLine1\n            addressLine2\n            zipCode\n            city\n            state\n        }\n    }\n    }\n  }\n"], ["\n  query GaragingQuery($policyNumber: String, $guid: String) {\n    policyDetailsV2(policyNumber: $policyNumber, guid: $guid) {\n      isEOAIEligible\n      product{\n        programRsCode\n      }\n      drivers {\n        identifier\n        lastName\n        firstName\n        middleName\n        suffix\n    }\n    vehicles {\n        id\n        unitYear\n        make\n        model\n        bodyType\n        vin,\n        vehicleCC\n        vehicleDrivers {\n            driverId\n        }\n        insurableInterests {\n            nameBusiness\n        }\n        vehicleAddress {\n            sameAsPrimary\n            addressLine1\n            addressLine2\n            zipCode\n            city\n            state\n        }\n    }\n    }\n  }\n"])));
var templateObject_1, templateObject_2;
