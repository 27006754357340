import { TealiumUtagService } from './utag.service';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "./utag.service";
var TealiumTrackerService = /** @class */ (function () {
    function TealiumTrackerService(uTagService) {
        this.uTagService = uTagService;
        this.uTagService.setConfig({
            account: 'plymouth',
            profile: 'eservice',
            environment: environment.production ? 'prod' : 'qa'
        });
    }
    TealiumTrackerService.prototype.track = function (tealium_event, data) {
        this.uTagService.track(tealium_event, data);
    };
    TealiumTrackerService.prototype.view = function (data) {
        this.uTagService.track('view', data);
    };
    TealiumTrackerService.prototype.link = function (data) {
        this.uTagService.track('link', data);
    };
    TealiumTrackerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TealiumTrackerService_Factory() { return new TealiumTrackerService(i0.ɵɵinject(i1.TealiumUtagService)); }, token: TealiumTrackerService, providedIn: "root" });
    return TealiumTrackerService;
}());
export { TealiumTrackerService };
