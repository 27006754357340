import { MainComponent } from './main/main.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ForgotEmailComponent } from './forgot-email/forgot-email.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { EnrollEdocsComponent } from './enroll-edocs/enroll-edocs.component';
import { VerificationComponent } from './verification/verification.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import { InvitationLandingComponent } from './invitation-landing/invitation-landing.component';
import { AiLinkPolicyComponent } from './ai-link-policy/ai-link-policy.component';
import { DiscardChangesGuard, VerificationAuthorizeGuard } from '@prcins/utils';
var ɵ0 = { 'tealium': { 'subSection': 'Registration' } }, ɵ1 = { 'tealium': { 'subSection': 'Forgot Email' } }, ɵ2 = { 'tealium': { 'subSection': 'Forgot Password' } }, ɵ3 = { 'tealium': { 'subSection': 'eDoc:Sign Up' } }, ɵ4 = { 'tealium': { 'subSection': 'Verification' } }, ɵ5 = { 'tealium': { 'subSection': 'Create Password' } }, ɵ6 = { 'tealium': { 'subSection': 'Link Policies' } }, ɵ7 = { 'tealium': { 'subSection': 'Link Home And Auto Policy' } }, ɵ8 = { 'tealium': { 'subSection': 'Invitation' } };
var routes = [
    {
        path: 'login',
        component: MainComponent,
        children: [
            {
                path: '',
                component: LoginComponent
            }
        ]
    },
    {
        path: 'admin/login',
        component: MainComponent,
        children: [
            {
                path: '',
                component: LoginComponent
            }
        ]
    },
    {
        path: 'register',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ0,
                component: RegisterComponent
            }
        ]
    },
    {
        path: 'forgot-email',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ1,
                component: ForgotEmailComponent
            }
        ]
    },
    {
        path: 'forgot-password',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ2,
                component: ForgotPasswordComponent,
                canActivate: [VerificationAuthorizeGuard],
                canDeactivate: [DiscardChangesGuard],
            }
        ]
    },
    // {
    //   path: 'forgot-answer',
    //   component: MainComponent,
    //   children: [
    //     {
    //       path: '',
    //       data: { 'tealium': { 'subSection': 'Forgot Answer' } },
    //       component: ForgotAnswerComponent
    //     }
    //   ]
    // },
    // {
    //   path: 'reset-password',
    //   component: MainComponent,
    //   children: [
    //     {
    //       path: '',
    //       component: ResetPasswordComponent,
    //     }
    //   ]
    // },
    // {
    //   path: 'setup',
    //   component: MainComponent,
    //   children: [
    //     {
    //       path: '',
    //       data: { 'tealium': { 'subSection': 'Registration Details' } },
    //       component: SetupComponent
    //     }
    //   ]
    // },
    {
        path: 'edoc/signup/:userId/:policyNumber',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ3,
                component: EnrollEdocsComponent
            }
        ]
    },
    {
        path: 'verification',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ4,
                component: VerificationComponent,
                canActivate: [VerificationAuthorizeGuard]
            }
        ]
    },
    {
        path: 'create-password',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ5,
                component: CreatePasswordComponent,
                canActivate: [VerificationAuthorizeGuard],
                canDeactivate: [DiscardChangesGuard],
            }
        ]
    },
    {
        path: 'link-policies',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ6,
                component: LoginComponent
            }
        ]
    },
    {
        path: 'link-your-home-and-auto',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ7,
                component: AiLinkPolicyComponent
            }
        ]
    },
    {
        path: 'invitation',
        component: MainComponent,
        children: [
            {
                path: '',
                data: ɵ8,
                component: InvitationLandingComponent
            }
        ]
    },
    { path: 'registration', redirectTo: 'register', pathMatch: 'full' },
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'admin', redirectTo: 'admin/login', pathMatch: 'full' },
    { path: 'regUser.do', redirectTo: 'register', pathMatch: 'full' }
];
var LoginModule = /** @class */ (function () {
    function LoginModule() {
    }
    return LoginModule;
}());
export { LoginModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
