import { NGXLogger } from 'ngx-logger';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as i0 from "@angular/core";
import * as i1 from "ngx-logger";
import * as i2 from "ngx-device-detector";
/**
 * Facade for the underlying logger implementation
 */
var Logger = /** @class */ (function () {
    function Logger(logger, deviceService) {
        this.logger = logger;
        this.deviceService = deviceService;
    }
    Logger.prototype.debug = function (message) {
        var additional = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            additional[_i - 1] = arguments[_i];
        }
        this.logger.debug(message, additional);
    };
    Logger.prototype.info = function (message) {
        var additional = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            additional[_i - 1] = arguments[_i];
        }
        this.logger.info(message, additional);
    };
    Logger.prototype.warn = function (message) {
        var additional = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            additional[_i - 1] = arguments[_i];
        }
        this.logger.warn(message, additional);
    };
    Logger.prototype.error = function (message) {
        var additional = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            additional[_i - 1] = arguments[_i];
        }
        message = this.getDeviceInfo() + ' ' + message;
        this.logger.error(message, additional);
    };
    Logger.prototype.getDeviceInfo = function () {
        var message = '';
        var deviceInfo = this.deviceService.getDeviceInfo();
        if (deviceInfo) {
            message = 'Device: ' + deviceInfo.device + ', OS: ' + deviceInfo.os + ', OS_Version: ' + deviceInfo.os_version + ', Browser: ' + deviceInfo.browser + ', Browser_version: ' + deviceInfo.browser_version;
        }
        return message;
    };
    Logger.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Logger_Factory() { return new Logger(i0.ɵɵinject(i1.NGXLogger), i0.ɵɵinject(i2.DeviceDetectorService)); }, token: Logger, providedIn: "root" });
    return Logger;
}());
export { Logger };
