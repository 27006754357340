import { ApplicationRef, ComponentFactoryResolver } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { StaticInjectorService } from "../services/static-injector.service";
import { SessionRedirectErrorComponent } from "../session-redirect-error/session-redirect-error.component";
import { SpinnerComponent } from "../spinner/spinner.component";
export function SessionInitializer(configuration) {
    return function (target) {
        var preProcessorComplete$ = new BehaviorSubject(null);
        target.prototype['preProcessorComplete$'] = preProcessorComplete$;
        var initializerComplete$ = new BehaviorSubject(null);
        target.prototype['initializerComplete$'] = initializerComplete$;
        var postProcessorComplete$ = new BehaviorSubject(null);
        target.prototype['postProcessorComplete$'] = postProcessorComplete$;
        var route = StaticInjectorService.Injector.get(ActivatedRoute);
        var appRef = StaticInjectorService.Injector.get(ApplicationRef);
        var componentFactoryResolver = StaticInjectorService.Injector.get(ComponentFactoryResolver);
        var rootComp = appRef.components[0];
        var urlParams = new URLSearchParams(window.location.search);
        var restoreSessionParam = urlParams.get('restoreSession');
        if (route.snapshot.queryParams['restoreSession'] === 'true' || restoreSessionParam === 'true') {
            var _pointcut = configuration.pointcut || 'ngOnInit';
            var _actual_1 = target.prototype[_pointcut];
            var _preProcessor_1 = target.prototype[configuration.preProcessor || 'preProcessor'] || (function () { preProcessorComplete$.next(true); });
            var _postProcessor_1 = target.prototype[configuration.postProcessor || 'postProcessor'] || (function () { postProcessorComplete$.next(true); });
            target.prototype[_pointcut] = function () {
                var _this = this;
                var args = [];
                for (var _i = 0; _i < arguments.length; _i++) {
                    args[_i] = arguments[_i];
                }
                var spinnerComponentFactory = componentFactoryResolver.resolveComponentFactory(SpinnerComponent);
                var errorComponentFactory = componentFactoryResolver.resolveComponentFactory(SessionRedirectErrorComponent);
                var _spinnerComponentRef = rootComp.instance.viewRef.createComponent(spinnerComponentFactory);
                _preProcessor_1 && _actual_1 !== _preProcessor_1 && _preProcessor_1.apply(this);
                preProcessorComplete$.asObservable().subscribe(function (preProcessorCompleted) {
                    if (preProcessorCompleted !== null) {
                        if (preProcessorCompleted) {
                            _actual_1 && _actual_1.apply(_this, args);
                            initializerComplete$.asObservable().subscribe(function (initializerCompleted) {
                                if (initializerCompleted !== null) {
                                    if (initializerCompleted) {
                                        _postProcessor_1 && _postProcessor_1.apply(_this);
                                        postProcessorComplete$.asObservable().subscribe(function (postProcessorCompleted) {
                                            if (postProcessorCompleted !== null) {
                                                if (postProcessorCompleted) {
                                                    _spinnerComponentRef.destroy();
                                                }
                                                else {
                                                    _spinnerComponentRef.destroy();
                                                    rootComp.instance.viewRef.createComponent(errorComponentFactory);
                                                }
                                            }
                                        });
                                    }
                                    else {
                                        _spinnerComponentRef.destroy();
                                        rootComp.instance.viewRef.createComponent(errorComponentFactory);
                                    }
                                }
                            });
                        }
                        else {
                            _spinnerComponentRef.destroy();
                            rootComp.instance.viewRef.createComponent(errorComponentFactory);
                        }
                    }
                });
            };
        }
    };
}
