import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from "rxjs/operators";
import { ROADREWARD_URL } from '@prcins/constants';
import { HttpClient } from '@angular/common/http';
import { GaragingQuery, PolicyDriversQuery, PolicyDiscountsQuery, PolicyPerksQuery, PolicyPremiumDetailsQuery, PropertyInfoQuery, CoverageDetailsQuery, CoverageDetailsHomeQuery, PolicyCrossSellPromotionsQuery } from "@prcins/utils";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "apollo-angular";
var PolicyProviderService = /** @class */ (function () {
    function PolicyProviderService(http, apollo) {
        this.http = http;
        this.apollo = apollo;
    }
    PolicyProviderService.prototype.getCrossSellPromotions = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyCrossSellPromotionsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PolicyProviderService.prototype.getDriverDetails = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyDriversQuery,
            fetchPolicy: 'network-only',
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyDetails;
        }));
    };
    PolicyProviderService.prototype.getVehicleDetails = function (policyNumber, guid) {
        return this.apollo
            .watchQuery({
            query: GaragingQuery,
            variables: {
                policyNumber: policyNumber,
                guid: guid
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyDetailsV2;
        }));
    };
    PolicyProviderService.prototype.getPerksDetails = function (policyNumber) {
        return this.apollo.watchQuery({
            query: PolicyPerksQuery,
            variables: {
                policyNumber: policyNumber,
            },
        }).valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyPerks;
        }));
    };
    PolicyProviderService.prototype.getDiscountDetails = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyDiscountsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyDetails.discounts;
        }));
    };
    PolicyProviderService.prototype.getPolicyPremiumDetails = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PolicyPremiumDetailsQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data.policyDetails.premium;
        }));
    };
    PolicyProviderService.prototype.getPropertyInfo = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: PropertyInfoQuery,
            variables: {
                policyNumber: policyNumber
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PolicyProviderService.prototype.getCoverageDetails = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: CoverageDetailsQuery,
            variables: {
                policyNumber: policyNumber
            },
            fetchPolicy: "no-cache"
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PolicyProviderService.prototype.getHomeCoverageDetails = function (policyNumber) {
        return this.apollo
            .watchQuery({
            query: CoverageDetailsHomeQuery,
            variables: {
                policyNumber: policyNumber
            }
        })
            .valueChanges.pipe(map(function (_a) {
            var data = _a.data;
            return data;
        }));
    };
    PolicyProviderService.prototype.addUpdateTextAlertDetails = function (payload) {
    };
    PolicyProviderService.prototype.getRRDriversDetails = function (policyNumber) {
        return this.http.get(ROADREWARD_URL.DriverDetails + '/' + policyNumber);
    };
    PolicyProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PolicyProviderService_Factory() { return new PolicyProviderService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Apollo)); }, token: PolicyProviderService, providedIn: "root" });
    return PolicyProviderService;
}());
export { PolicyProviderService };
