import { Apollo } from 'apollo-angular';
import { EdocsQuery } from '../graphql/edocs-schema';
import { RightRailsEdocsQuery } from '../graphql/rightrails-schema';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "apollo-angular";
var EdocsBroadcasterService = /** @class */ (function () {
    function EdocsBroadcasterService(apollo) {
        this.apollo = apollo;
        this.notify$ = new Subject();
    }
    EdocsBroadcasterService.prototype.getEdocs = function (username) {
        try {
            return this.apollo.getClient().readQuery({
                query: EdocsQuery,
                variables: {
                    username: username
                }
            });
        }
        catch (e) {
            return null;
        }
    };
    EdocsBroadcasterService.prototype.updateEdocs = function (username, edocs) {
        return this.apollo.getClient().writeQuery({
            query: EdocsQuery,
            variables: {
                username: username
            },
            data: edocs
        });
    };
    EdocsBroadcasterService.prototype.updateShowEdocs = function (policyNumber, showEdocs) {
        try {
            var rightRails = this.apollo.getClient().readQuery({
                query: RightRailsEdocsQuery,
                variables: {
                    policyNumber: policyNumber
                }
            });
            rightRails.rightRail.showEdocs = showEdocs;
            if (rightRails) {
                this.apollo.getClient().writeQuery({
                    query: RightRailsEdocsQuery,
                    variables: {
                        policyNumber: policyNumber
                    },
                    data: rightRails
                });
            }
        }
        catch (e) { }
    };
    EdocsBroadcasterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EdocsBroadcasterService_Factory() { return new EdocsBroadcasterService(i0.ɵɵinject(i1.Apollo)); }, token: EdocsBroadcasterService, providedIn: "root" });
    return EdocsBroadcasterService;
}());
export { EdocsBroadcasterService };
