import { OnInit, EventEmitter } from '@angular/core';
var TogglerComponent = /** @class */ (function () {
    function TogglerComponent() {
        this.sliderChanged = new EventEmitter();
    }
    Object.defineProperty(TogglerComponent.prototype, "sliderValue", {
        get: function () {
            return this._sliderValue;
        },
        set: function (value) {
            this._sliderValue = value;
            this.checkValue = !!value;
        },
        enumerable: true,
        configurable: true
    });
    TogglerComponent.prototype.ngOnInit = function () {
        this.checkValue = !!this.sliderValue;
    };
    TogglerComponent.prototype.onSliderChange = function () {
        if (!this.disabled) {
            this.sliderValue = !this.sliderValue;
            this.sliderChanged.next({ key: this.sliderKey, value: this.sliderValue });
        }
    };
    return TogglerComponent;
}());
export { TogglerComponent };
