import { HttpClient } from "@angular/common/http";
import { environment } from "@prcins/environments";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var FeatureFlagService = /** @class */ (function () {
    function FeatureFlagService(http) {
        this.http = http;
    }
    FeatureFlagService.prototype.get = function (flag) {
        return this.http.get(environment.BASE_URL + '/mfa/login-feature-flag/' + flag);
    };
    FeatureFlagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeatureFlagService_Factory() { return new FeatureFlagService(i0.ɵɵinject(i1.HttpClient)); }, token: FeatureFlagService, providedIn: "root" });
    return FeatureFlagService;
}());
export { FeatureFlagService };
