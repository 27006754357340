import * as tslib_1 from "tslib";
import { TogglerComponent } from '../toggler/toggler.component';
var SquareCheckboxComponent = /** @class */ (function (_super) {
    tslib_1.__extends(SquareCheckboxComponent, _super);
    function SquareCheckboxComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return SquareCheckboxComponent;
}(TogglerComponent));
export { SquareCheckboxComponent };
/* -- Usage ----
<prcins-square-checkbox
  [sliderValue]="value"
  [sliderKey]="key"
  (sliderChanged)="onChanged($event)">
</prcins-square-checkbox>
-- */ 
