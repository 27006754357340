import * as tslib_1 from "tslib";
var Wallet = /** @class */ (function () {
    function Wallet() {
    }
    return Wallet;
}());
export { Wallet };
var Card = /** @class */ (function (_super) {
    tslib_1.__extends(Card, _super);
    function Card() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Card;
}(Wallet));
export { Card };
var Bank = /** @class */ (function (_super) {
    tslib_1.__extends(Bank, _super);
    function Bank() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Bank;
}(Wallet));
export { Bank };
