import * as tslib_1 from "tslib";
import { CardBaseForm } from '../card-base-form/card-base';
var CardFormComponent = /** @class */ (function (_super) {
    tslib_1.__extends(CardFormComponent, _super);
    function CardFormComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.helps = {
            cardNumber: false,
            securityCode: false,
            expirationDate: false
        };
        _this.helpFlags = tslib_1.__assign({}, _this.helps);
        _this.cvvLengthError = false;
        return _this;
    }
    CardFormComponent.prototype.handleMaskOnBlur = function (maskedControlName, actualControlName, cvv) {
        var maskedControl = this.cardForm.get(maskedControlName);
        var actualControl = this.cardForm.get(actualControlName);
        var actualValue = maskedControl.value;
        actualControl.markAsTouched();
        if (cvv) {
            var mask = '';
            for (var i = 0; i < actualValue.length; i++) {
                mask += '*';
            }
            maskedControl.setValue(mask);
            this.cvvLengthError = false;
        }
        else {
            if (actualValue.length >= 4) {
                maskedControl.setValue('************' + actualValue.slice(-4));
            }
        }
    };
    CardFormComponent.prototype.handleMaskOnFocus = function (maskedControlName, actualControlName) {
        var maskedControl = this.cardForm.get(maskedControlName);
        var actualControl = this.cardForm.get(actualControlName);
        maskedControl.setValue(actualControl.value);
    };
    CardFormComponent.prototype.showHelpText = function (key) {
        this.helpFlags = tslib_1.__assign({}, this.helps);
        this.helpFlags[key] = true;
    };
    CardFormComponent.prototype.hideHelpText = function () {
        this.helpFlags = tslib_1.__assign({}, this.helps);
    };
    return CardFormComponent;
}(CardBaseForm));
export { CardFormComponent };
