import { HttpClient } from '@angular/common/http';
import { environment } from '@prcins/environments';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UploadAdminDocsService = /** @class */ (function () {
    function UploadAdminDocsService(http) {
        this.http = http;
    }
    UploadAdminDocsService.prototype.uploadBulkInviteDocument = function (formData) {
        if (formData === void 0) { formData = new FormData(); }
        var url = environment.BASE_URL + '/admin/uploadFileToS3/';
        return this.http.post(url, formData);
    };
    UploadAdminDocsService.prototype.uploadEndorsementBlockDocument = function (formData) {
        if (formData === void 0) { formData = new FormData(); }
        var url = environment.BASE_URL + '/endorsement/block/config/upload';
        return this.http.post(url, formData);
    };
    UploadAdminDocsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UploadAdminDocsService_Factory() { return new UploadAdminDocsService(i0.ɵɵinject(i1.HttpClient)); }, token: UploadAdminDocsService, providedIn: "root" });
    return UploadAdminDocsService;
}());
export { UploadAdminDocsService };
