import { OnInit } from '@angular/core';
import { BRAND, Brand, CURRENT_POLICY } from '@prcins/constants';
import { RenewalquestionnaireService } from '../services/renewalquestionnaire.service';
// import { AccountsProviderService } from '../services/accounts-provider.service';
//import { AlertPreferences } from '@prcins/utils';
// import { take } from 'rxjs/operators';
// import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { dateWithinRange } from '../../../../../apps/endorsements/add-driver/src/app/validators';
import { FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DropdownService, DropDownName } from '../../../../../libs/features/personal-auto/src/lib/vehicle/services/dropdown.service';
var AdditionalDriversDetailsComponent = /** @class */ (function () {
    function AdditionalDriversDetailsComponent(route, router, dropdownService, formBuilder, renewalQuestion) {
        this.route = route;
        this.router = router;
        this.dropdownService = dropdownService;
        this.formBuilder = formBuilder;
        this.renewalQuestion = renewalQuestion;
        this.brand = localStorage.getItem(BRAND);
        this.mainPage = Brand[this.brand].urlEnterpriseWeb;
        this.MIN_INSURABLE_AGE = 15;
        this.MAX_INSURABLE_AGE = 99;
        this.isLoading = false;
        this.driverForm = this.formBuilder.group({
            drivers: this.formBuilder.array([])
        });
        console.log('calling adddriver row');
        this.addDriverRow();
    }
    AdditionalDriversDetailsComponent.prototype.licenseStatusChange = function (e, cntrl) {
        // console.log('am i here ?', cntrl.controls.licenseStatus.value)
        var ctl = cntrl.controls;
        // console.log('printing ctl', ctl)
        if (cntrl.controls.licenseStatus.value !== 'active' && cntrl.controls.licenseStatus.value !== 'permit') {
            cntrl.controls.licenseState.patchValue({ 'licenseState': '' });
            cntrl.controls.licenseNumber.patchValue('');
            cntrl.controls.licenseState.disable();
            cntrl.controls.licenseNumber.disable();
        }
        else {
            cntrl.controls.licenseState.enable();
            cntrl.controls.licenseNumber.enable();
        }
    };
    AdditionalDriversDetailsComponent.prototype.addDriverRow = function () {
        console.log('Log inside addDriverRow method');
        var driverGroup = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.pattern("([a-zA-Z -.,']+)")]],
            lastName: ['', [Validators.required, Validators.pattern("([a-zA-Z -.,']+)")]],
            dob: ['', [Validators.required, dateWithinRange(this.MIN_INSURABLE_AGE, this.MAX_INSURABLE_AGE)]],
            licenseStatus: ['', [Validators.required]],
            licenseState: ['', [Validators.required]],
            licenseNumber: ['', [Validators.required]]
        });
        console.log('Pushing the driver group');
        this.driverControls.push(driverGroup);
    };
    Object.defineProperty(AdditionalDriversDetailsComponent.prototype, "driverControls", {
        get: function () {
            console.log('Log inside getter method');
            return this.driverForm.get('drivers');
        },
        enumerable: true,
        configurable: true
    });
    AdditionalDriversDetailsComponent.prototype.removeDriver = function (driverIndex) {
        if (this.driverControls.length > driverIndex) {
            this.driverControls.removeAt(driverIndex);
        }
    };
    AdditionalDriversDetailsComponent.prototype.onSubmit = function () {
        var _this = this;
        console.log("Log inside onSubmit method");
        console.log(this.driverForm);
        var driverData = this.driverControls.value;
        console.log(driverData);
        var data = {
            policyNumber: sessionStorage.getItem(CURRENT_POLICY),
            driverDetails: driverData
        };
        this.isLoading = true;
        this.renewalQuestion.getDriverDetails(data).subscribe(function (response) {
            console.log('POST request successful', response);
            _this.router.navigate(["../confirmation"], {
                relativeTo: _this.route,
                state: data
            });
            _this.isLoading = false;
        }, function (error) {
            console.error('POST request error', error);
            _this.isLoading = false;
            _this.router.navigate(["../error"], {
                relativeTo: _this.route
            });
        });
        // if (this.driverForm.valid) {
        //   console.log("Driver form is valid and redirecting")
        //   const driverData = this.driverControls.value;
        //   console.log(driverData);
        //   this.router.navigate(['../additionaldriversrspn'], { state: { driverData } });
        // }
        // this.router.navigate(['../additionaldriversrspn'], {
        //   relativeTo: this.route
        // });
    };
    AdditionalDriversDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.dropdownService.getDropdownEntries({
            UI_Component: "eService",
            List_Name: DropDownName.US_STATE
        }).subscribe(function (resp) {
            console.log(resp);
            if (resp) {
                _this._usStateOptions = resp.US_STATE;
            }
        });
    };
    return AdditionalDriversDetailsComponent;
}());
export { AdditionalDriversDetailsComponent };
