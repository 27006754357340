import * as tslib_1 from "tslib";
import { ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UsDateFormatMaskDirective } from './us-date-format-mask.directive';
/**
 * An extension of USDateFormatMaskDirective that replaces the month and date fields
 * with '*' when the user focuses out of the field and when rendering the initial
 * field value.
 */
var SecureUsDateFormatMaskDirective = /** @class */ (function (_super) {
    tslib_1.__extends(SecureUsDateFormatMaskDirective, _super);
    function SecureUsDateFormatMaskDirective(ngControl, elemRef) {
        var _this = _super.call(this, ngControl) || this;
        _this.ngControl = ngControl;
        _this.elemRef = elemRef;
        return _this;
    }
    SecureUsDateFormatMaskDirective.prototype.onFocus = function () {
        var date = this.ngControl.value;
        var month;
        var day;
        var year = '';
        if (date != '') {
            month = date.split('/')[0];
            if (month.length === 1) {
                month = '0' + month;
            }
            day = date.split('/')[1];
            if (day.length === 1) {
                day = '0' + day;
            }
            if (date.length >= 7) {
                year = date.split('/')[2];
            }
            this.ngControl.valueAccessor.writeValue(month + '/' + day + '/' + year);
        }
        else {
            this.ngControl.valueAccessor.writeValue(this.ngControl.value);
        }
    };
    return SecureUsDateFormatMaskDirective;
}(UsDateFormatMaskDirective));
export { SecureUsDateFormatMaskDirective };
